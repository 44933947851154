import {
  ColDef,
  ColumnApi,
  GridApi,
  SendToClipboardParams,
} from 'ag-grid-community';
import { AgGridColumnProps } from 'ag-grid-react';
import { format } from 'date-fns';
import DOMPurify from 'isomorphic-dompurify';
import * as R from 'ramda';

import { ExploreTable, FILTER_TYPES_FROM_PARAMS, ModelName } from './enum';

/**
 * Checks if a string fits into a cell.
 */
const ellipsisIsShowing = (cellWidth: number, value: string): boolean => {
  if (!value || !cellWidth) return false;

  const numberСharactersPer100Pixels = 11;
  const numberPixelsPerCharacter = Math.ceil(
    100 / numberСharactersPer100Pixels,
  );

  return cellWidth / value.length < numberPixelsPerCharacter;
};

type GenerateColumnDefsProps = {
  modelDefinitions: Array<Array<string>>;
  tableName?: string;
  modelName?: ModelName;
};

/**
 * Generate columns definition.
 */
export const generateColumnDefs = ({
  modelDefinitions,
  tableName,
  modelName,
}: GenerateColumnDefsProps) => [
  {
    headerCheckboxSelectionFilteredOnly: true,
    checkboxSelection: true,
    floatingFilter: false,
    maxWidth: 55,
    pinned: 'left' as ColDef['pinned'],
    sortable: false,
    suppressMenu: true,
    cellStyle: {
      'pointer-events': 'none',
    },
    suppressNavigable: true,
    cellClass: 'checkbox-selection-cell',
  } as AgGridColumnProps,
  ...modelDefinitions.map((d: Array<string>) => {
    const name = d[0];
    const type = d[1];
    const columnProperty: AgGridColumnProps = {
      headerName: name,
      field: name,
      cellRenderer: (props: any) => DOMPurify.sanitize(props.value),
      colId: name,
      tooltipValueGetter: ({ column, value }) =>
        ellipsisIsShowing(column?.getActualWidth() as number, value)
          ? value
          : '',
    };

    if (type === 'bool') {
      columnProperty.filter = 'set';
      columnProperty.floatingFilterComponent = 'selectWithClearButton';
      columnProperty.filterParams = {
        buttons: ['reset'],
        values: ['false', 'true', 'null'],
      };

      columnProperty.cellRenderer = (props: any) => {
        return props.value === null || props.value === undefined
          ? ''
          : props.value.toString();
      };
    }

    if (type === 'datetime.datetime') {
      columnProperty.valueGetter = ({
        data,
      }: {
        data: Record<string, string>;
      }) =>
        data && data[name]
          ? format(new Date(data[name]), 'yyyy/MM/dd h:mm a')
          : '';
      columnProperty.filter = 'agDateColumnFilter';
      columnProperty.floatingFilterComponent = 'customDateFloatingComponent';
      columnProperty.filterParams = {
        buttons: ['reset'],
        filterOptions: [
          'equals',
          'lessThan',
          'greaterThan',
          'notEqual',
          'inRange',
        ],
        comparator: (filterDate: Date, cellValue: string) => {
          if (!cellValue) {
            return 0;
          }
          /**
           * cellValue has date and time, but filter only works with date so we cut out time.
           * ex: cellValue = "2020/02/02 12:00 AM", cellValueToArr = [2020, 2, 2]
           */
          const cellValueToArr = cellValue.slice(0, 10).split('/').map(Number);
          const cellDate = new Date(
            Number(cellValueToArr[0]),
            Number(cellValueToArr[1]) - 1, // cellValue month = 1-12, new Date() expects 0-11
            Number(cellValueToArr[2]),
          );

          if (cellDate.getTime() === filterDate.getTime()) {
            return 0;
          }
          if (cellDate.getTime() < filterDate.getTime()) {
            return -1;
          }
          if (cellDate.getTime() > filterDate.getTime()) {
            return 1;
          }
        },
      };
    }

    if (type === 'int' || type === 'float') {
      /**
       * For INT we leave agTextColumnFilter to be able to enter numbers separated by commas.
       * But set list of options(filterOptions) for number.
       */

      columnProperty.cellRenderer = (props: any) =>
        props.value === null ? '' : props.value;

      columnProperty.filterParams = {
        filterOptions: [
          'equals',
          'notEqual',
          'lessThan',
          'lessThanOrEqual',
          'greaterThan',
          'greaterThanOrEqual',
          'inRange',
          {
            displayKey: 'inSet',
            displayName: 'In Set',
            predicate: () => '',
          },
        ],
      };
      columnProperty.cellStyle = {
        textAlign: 'right',
      };
    }

    if (type === 'str') {
      columnProperty.filterParams = {
        filterOptions: [
          'contains',
          'notContains',
          'equals',
          'notEqual',
          'startsWith',
          'endsWith',
          {
            displayKey: 'inSet',
            displayName: 'In Set',
            predicate: () => '',
          },
        ],
      };
    }

    if (
      (name === 'db_id' || name === 'id') &&
      tableName !== ExploreTable.KBRA
    ) {
      columnProperty.maxWidth = 105;
    }

    if (name === 'code') {
      columnProperty.maxWidth = 90;
    }

    if (
      name === 'name' &&
      (modelName === ModelName.Process || modelName === ModelName.Material)
    ) {
      columnProperty.minWidth = 230;
    }

    if (columnProperty.filterParams) {
      columnProperty.filterParams.debounceMs = 700;
    }
    return columnProperty;
  }),
];

/**
 * Get the name of the exported file.
 */
export const getExportFileName = (
  pathname = '',
  menuName = '',
  prefix = '',
  modelApi: ExploreTable,
): string => {
  const normalizedMenuName = menuName.replace(/ /gi, '-');

  const isBfdPage = pathname.includes('bfd');

  const modelNameFromBFD =
    isBfdPage &&
    [
      ExploreTable.KMEA,
      ExploreTable.KMAT,
      ExploreTable.KPRO,
      ExploreTable.KBRA,
    ].includes(modelApi)
      ? `_${modelApi}`
      : '';

  return (
    `${pathname.slice(1)}_${normalizedMenuName}`.toLocaleLowerCase() +
    `${modelNameFromBFD}_${format(new Date(), 'yy/MM/dd-h:mma')}` +
    prefix
  );
};

/**
 * Export AgGrid table.
 */
export const tableExportAgGrid = (
  menuData: Record<string, any>,
  gridApi: GridApi | null,
  pathname: string,
  modelApi: ExploreTable,
) => {
  const { name = '', format = '', onlySelected = false } = menuData;
  const fileName = getExportFileName(pathname, name, '', modelApi);
  if (format === 'csv') {
    gridApi?.exportDataAsCsv({
      fileName,
      onlySelected,
    });
  }
  if (format === 'xlsx') {
    gridApi?.exportDataAsExcel({
      fileName,
      onlySelected,
    });
  }
};

type SetFilterFromQueryAndMoveColumnsProps = {
  gridApi: GridApi | null;
  columnApi: ColumnApi | null;
  search: string;
  filterModel: Record<string, any>;
  modelDefinitions: Array<Array<string>>;
  isFilterInitialized: boolean;
  showCancel: boolean | null;
  setIsFilterInitialized: React.Dispatch<React.SetStateAction<boolean>>;
  setSearchParams: (url: string) => void;
};

export const sendToClipboardWithOrder = (
  { data }: SendToClipboardParams,
  columnApi: ColumnApi | null,
) => {
  const sortedByAsc =
    columnApi?.getColumnState().find((row) => row.sort)?.sort == 'asc';

  // range selection without headers
  if (!data.includes('db_id')) {
    const columnCount = columnApi?.getAllColumns()?.length ?? 0;

    const infoToArray = data
      .split('\r\n')
      .map((row) => row.split('\t').slice(1)); // remove first empty string

    const isRowsFullSelected = !infoToArray.some(
      (rowData) => rowData.length !== columnCount - 1,
    ); // exclude checkbox column

    if (isRowsFullSelected) {
      const sortedData = infoToArray.sort((a, b) =>
        +a[0] - +b[0] > 0 ? (sortedByAsc ? 1 : -1) : sortedByAsc ? -1 : 1,
      );

      return navigator.clipboard.writeText(
        sortedData
          .map((item) => item.join(',').replaceAll(',', '\t').concat('\r\n'))
          .join('')
          .toString(),
      );
    }

    return navigator.clipboard.writeText(data.toString());
  }

  const infoToArray = data
    .split('\r\n')
    .map((row, index) => (index === 0 ? row : row.split('\t').slice(1))); // remove first empty string

  const sortedData = infoToArray
    .slice(1) // exclude headers
    .sort((a, b) =>
      +a[0] - +b[0] > 0 ? (sortedByAsc ? 1 : -1) : sortedByAsc ? -1 : 1,
    );

  navigator.clipboard.writeText(
    [infoToArray[0].toString().replace('\t', '').concat('\r\n'), ...sortedData]
      .map((item) =>
        typeof item === 'string' // headers
          ? item
          : item.join(',').replaceAll(',', '\t').concat('\r\n'),
      )
      .join('')
      .toString(),
  );
};

const generateSimpleFilter = (
  fieldType: string,
  filterValue: string,
  isInSetFilter: boolean,
  queryOperator: string,
  isInRangeFilter: boolean,
) => {
  const isIntType = fieldType === 'int' || fieldType === 'float';
  const isStrType = fieldType === 'str';
  const isDateType = fieldType === 'datetime.datetime';
  const isBoolType = fieldType === 'bool';

  const type =
    FILTER_TYPES_FROM_PARAMS[
      queryOperator as keyof typeof FILTER_TYPES_FROM_PARAMS
    ];

  if (isIntType) {
    if (isInRangeFilter) {
      const [firstValue, secondValue] = filterValue.split(',');

      return {
        filter: firstValue,
        filterTo: secondValue,
        filterType: 'number',
        type: 'inRange',
      };
    }

    return {
      filter: filterValue,
      filterType: 'number',
      type: type ? type : isInSetFilter ? 'inSet' : 'equals',
    };
  }

  if (isStrType) {
    return {
      filter: filterValue,
      filterType: 'text',
      type: type ? type : isInSetFilter ? 'inSet' : 'contains',
    };
  }
  if (isDateType) {
    if (isInRangeFilter) {
      const [firstValue, secondValue] = filterValue.split(',');

      return {
        dateFrom: firstValue,
        dateTo: secondValue,
        filterType: 'date',
        type: 'inRange',
      };
    }

    return {
      dateFrom: filterValue,
      dateTo: null,
      filterType: 'date',
      type: type ? type : 'equals',
    };
  }
  if (isBoolType) {
    const values = filterValue.split(',').map((x) => `${x}`);

    return {
      filterType: 'set',
      values,
    };
  }
};

const generateConditionalFilter = (
  fieldType: string,
  firstQueryOperator: string,
  secondQueryOperator: string,
  firstQueryFilterValues: string,
  secondQueryFilterValues: string,
  isInSetFirstFilter: boolean,
  isInSetSecondFilter: boolean,
  operator: string,
  isInRangeFirstFilter: boolean,
  isInRangeSecondFilter: boolean,
) => {
  const isIntType = fieldType === 'int' || fieldType === 'float';
  const isStrType = fieldType === 'str';
  const isDateType = fieldType === 'datetime.datetime';

  const firstType =
    FILTER_TYPES_FROM_PARAMS[
      firstQueryOperator as keyof typeof FILTER_TYPES_FROM_PARAMS
    ];
  const secondType =
    FILTER_TYPES_FROM_PARAMS[
      secondQueryOperator as keyof typeof FILTER_TYPES_FROM_PARAMS
    ];

  if (isIntType) {
    const initialFilter: Record<string, any> = {
      condition1: {
        filter: firstQueryFilterValues,
        filterType: 'number',
        type: isInSetFirstFilter ? 'inSet' : firstType,
      },
      condition2: {
        filter: secondQueryFilterValues,
        filterType: 'number',
        type: isInSetSecondFilter ? 'inSet' : secondType,
      },
      filterType: 'number',
      operator: operator,
    };

    if (isInRangeFirstFilter) {
      const [filter, filterTo] = firstQueryFilterValues.split(',');

      initialFilter.condition1.filter = filter;
      initialFilter.condition1.filterTo = filterTo;
      initialFilter.condition1.type = 'inRange';
    }

    if (isInRangeSecondFilter) {
      const [filter, filterTo] = secondQueryFilterValues.split(',');

      initialFilter.condition2.filter = filter;
      initialFilter.condition2.filterTo = filterTo;
      initialFilter.condition2.type = 'inRange';
    }

    return initialFilter;
  }

  if (isStrType) {
    return {
      condition1: {
        filter: firstQueryFilterValues,
        filterType: 'text',
        type: isInSetFirstFilter ? 'inSet' : firstType,
      },
      condition2: {
        filter: secondQueryFilterValues,
        filterType: 'text',
        type: isInSetSecondFilter ? 'inSet' : secondType,
      },
      filterType: 'text',
      operator: operator,
    };
  }
  if (isDateType) {
    const filter: Record<string, any> = {
      condition1: {
        dateFrom: firstQueryFilterValues,
        dateTo: null,
        filterType: 'date',
        type: firstType,
      },
      condition2: {
        dateFrom: secondQueryFilterValues,
        dateTo: null,
        filterType: 'date',
        type: secondType,
      },
      filterType: 'date',
      operator: operator,
    };

    if (isInRangeFirstFilter) {
      const [dateFrom, dateTo] = firstQueryFilterValues.split(',');

      filter.condition1.dateFrom = dateFrom;
      filter.condition1.dateTo = dateTo;
      filter.condition1.type = 'inRange';
    }

    if (isInRangeSecondFilter) {
      const [dateFrom, dateTo] = secondQueryFilterValues.split(',');

      filter.condition2.dateFrom = dateFrom;
      filter.condition2.dateTo = dateTo;
      filter.condition2.type = 'inRange';
    }

    return filter;
  }
};

/**
 * Return filterModel from passed params.
 * You can pass params as object { [key]: string } or query string as '?key=value'
 */
export const getFilterModelFromParams = (
  modelDefinition: Array<Array<string>>,
  queryString: string,
) => {
  const queryFields = decodeURIComponent(queryString)
    .replace('voided=0', '')
    .replace('voided=1', '')
    .replace('?', '')
    .split('&')
    .filter((x) => x !== '');

  let filterModel = {};

  if (queryFields.length === 0) {
    return filterModel;
  }

  for (const queryField of queryFields) {
    const hasAndOperator = queryField.includes('*:');
    const hasRrOperator = queryField.includes('|:');
    const hasOperator = hasAndOperator || hasRrOperator;

    if (!hasOperator) {
      const [[fieldName, queryOperator], [queryFilterValues]] = queryField
        .replace('*:', ':')
        .replace('|:', ':')
        .split('=')
        .map((x) => x.split(/:(co|nco|eq|neq|sw|ew|in|gt|ir|gte|lte)/));

      const fieldType = modelDefinition.find((x) => x[0] === fieldName)?.[1];

      if (!fieldType) {
        return null;
      }

      const isInSetFilter =
        queryOperator === 'in' ||
        (queryFilterValues.split(',').length > 1 && queryOperator !== 'ir');

      const isInRangeFilter =
        queryFilterValues.split(',').length > 1 && queryOperator === 'ir';

      filterModel = {
        ...filterModel,
        [fieldName]: generateSimpleFilter(
          fieldType,
          queryFilterValues,
          isInSetFilter,
          queryOperator,
          isInRangeFilter,
        ),
      };
    } else {
      const [
        [fieldName, firstQueryOperator],
        [firstQueryFilterValues, secondQueryOperator],
        [secondQueryFilterValues],
      ] = queryField
        .replace('*:', ':')
        .replace('|:', ':')
        .split('=')
        .map((x) => x.split(/:(co|nco|eq|neq|sw|ew|in|gt|lt|ir|gte|lte)/));

      const fieldType = modelDefinition.find((x) => x[0] === fieldName)?.[1];

      if (!fieldType) {
        return null;
      }

      const operator = hasAndOperator ? 'AND' : 'OR';

      const isInSetFirstFilter =
        firstQueryOperator === 'in' ||
        (firstQueryFilterValues.split(',').length > 1 &&
          firstQueryOperator !== 'ir');

      const isInSetSecondFilter =
        secondQueryOperator === 'in' ||
        (secondQueryFilterValues.split(',').length > 1 &&
          secondQueryOperator !== 'ir');

      const isInRangeFirstFilter =
        firstQueryFilterValues.split(',').length > 1 &&
        firstQueryOperator === 'ir';

      const isInRangeSecondFilter =
        secondQueryFilterValues.split(',').length > 1 &&
        secondQueryOperator === 'ir';

      filterModel = {
        ...filterModel,
        [fieldName]: generateConditionalFilter(
          fieldType,
          firstQueryOperator,
          secondQueryOperator,
          firstQueryFilterValues,
          secondQueryFilterValues,
          isInSetFirstFilter,
          isInSetSecondFilter,
          operator,
          isInRangeFirstFilter,
          isInRangeSecondFilter,
        ),
      };
    }
  }

  return filterModel;
};

export const setFilterFromQueryAndMoveColumns = ({
  gridApi,
  columnApi,
  search,
  filterModel,
  modelDefinitions,
  isFilterInitialized,
  showCancel,
  setIsFilterInitialized,
  setSearchParams,
}: SetFilterFromQueryAndMoveColumnsProps) => {
  if (search && gridApi && R.isEmpty(filterModel)) {
    const filterModelFromQuery = getFilterModelFromParams(
      modelDefinitions,
      search,
    );

    if (
      !R.isEmpty(filterModelFromQuery) &&
      !R.isNil(filterModelFromQuery) &&
      !isFilterInitialized
    ) {
      gridApi.setFilterModel(filterModelFromQuery);
      columnApi?.moveColumns(
        R.keys(R.omit(['db_id'], filterModelFromQuery)),
        2,
      );

      setIsFilterInitialized(true);
    }
  }

  if (search && R.isEmpty(filterModel) && isFilterInitialized) {
    if (showCancel === null) {
      setSearchParams('');
    } else {
      setSearchParams(`?voided=${showCancel ? 1 : 0}`);
    }
  }
};

export const updateUrlSearchByFilterModel = (
  filter: Record<string, any>,
  showCancel: boolean,
) => {
  let searchLink = showCancel ? '?voided=1' : '?voided=0';

  if (Object.keys(filter).length > 0) {
    searchLink = Object.keys(filter).reduce((acc, fieldName) => {
      const currentFilterModel = filter[fieldName];

      const isConditional = currentFilterModel['condition1'];
      const hasOperator = currentFilterModel['operator'];

      acc = `${acc}&${fieldName}`;

      if (!isConditional || !hasOperator) {
        const type = currentFilterModel['type'];
        const filterType = currentFilterModel['filterType'];
        const isInRange = type === 'inRange';
        const isDateType = filterType === 'date';

        const filterTypeSign =
          filterType === 'set'
            ? 'in'
            : Object.entries(FILTER_TYPES_FROM_PARAMS).find(
                (val) => val[1] === type,
              )?.[0];

        if (isInRange) {
          if (isDateType) {
            const dateFrom = currentFilterModel['dateFrom'];
            const dateTo = currentFilterModel['dateTo'];

            acc = `${acc}:${filterTypeSign}=${dateFrom},${dateTo}`;
          } else {
            const filter = currentFilterModel['filter'];
            const filterTo = currentFilterModel['filterTo'];

            acc = `${acc}:${filterTypeSign}=${filter},${filterTo}`;
          }
        } else {
          if (isDateType) {
            const dateFrom = currentFilterModel['dateFrom'];

            acc = `${acc}:${filterTypeSign}=${dateFrom}`;
          } else {
            if (filterType === 'set') {
              const filter = currentFilterModel['values']
                .map((x: any) => `${x}`)
                .join(',');

              acc = `${acc}:${filterTypeSign}=${filter}`;
            } else {
              const filter = currentFilterModel['filter'];

              acc = `${acc}:${filterTypeSign}=${filter}`;
            }
          }
        }
      } else {
        const firstType = currentFilterModel['condition1']['type'];
        const firstFilterType = currentFilterModel['condition1']['filterType'];
        const isFirstTypeInRange = firstType === 'inRange';
        const isFirstTypeDate = firstFilterType === 'date';

        const secondType = currentFilterModel['condition2']['type'];
        const secondFilterType = currentFilterModel['condition2']['filterType'];
        const isSecondTypeInRange = secondType === 'inRange';
        const isSecondTypeDate = secondFilterType === 'date';

        const firstFilterTypeSign = Object.entries(
          FILTER_TYPES_FROM_PARAMS,
        ).find((val) => val[1] === firstType)?.[0];

        const secondFilterTypeSign = Object.entries(
          FILTER_TYPES_FROM_PARAMS,
        ).find((val) => val[1] === secondType)?.[0];

        if (isFirstTypeInRange || isSecondTypeInRange) {
          if (isFirstTypeDate || isSecondTypeDate) {
            const firstFilterFrom =
              currentFilterModel['condition1']['dateFrom'];
            const firstFilterTo = currentFilterModel['condition1']['dateTo'];
            const secondFilterFrom =
              currentFilterModel['condition2']['dateFrom'];
            const secondFilterTo = currentFilterModel['condition2']['dateTo'];

            const operator =
              currentFilterModel['operator'] === 'AND' ? '*' : '|';

            acc = `${acc}:${firstFilterTypeSign}=${firstFilterFrom},${firstFilterTo}${operator}:${secondFilterTypeSign}=${secondFilterFrom},${secondFilterTo}`;
          }
        } else {
          if (isFirstTypeDate || isSecondTypeDate) {
            const firstFilterFrom =
              currentFilterModel['condition1']['dateFrom'];
            const secondFilterFrom =
              currentFilterModel['condition2']['dateFrom'];

            const operator =
              currentFilterModel['operator'] === 'AND' ? '*' : '|';

            acc = `${acc}:${firstFilterTypeSign}=${firstFilterFrom}${operator}:${secondFilterTypeSign}=${secondFilterFrom}`;
          } else {
            const firstFilter = currentFilterModel['condition1']['filter'];
            const secondFilter = currentFilterModel['condition2']['filter'];

            const operator =
              currentFilterModel['operator'] === 'AND' ? '*' : '|';

            acc = `${acc}:${firstFilterTypeSign}=${firstFilter}${operator}:${secondFilterTypeSign}=${secondFilter}`;
          }
        }
      }

      return acc;
    }, searchLink);

    if (history) {
      // IE10+
      const newurl =
        window.location.protocol +
        '//' +
        window.location.host +
        window.location.pathname +
        encodeURI(searchLink);

      window.history.replaceState('', '', newurl);
    }
  }
};
