import * as R from 'ramda';

import * as actionTypes from 'modules/GlobalActionsTypes';

export const STATE_KEY = 'files';

const initialState = {
  files: {
    loading: false,
    data: [],
    error: false,
    filterModel: {},
    showCancel: false,
    selectedNodes: [],
    sortModel: [],
  },
};

const FilesReducer = (state = initialState, action: any) => {
  const { payload } = action;

  switch (action.type) {
    case actionTypes.FETCH_FILES_START: {
      return R.mergeDeepRight(state, { files: { loading: true } });
    }
    case actionTypes.FETCH_FILES_SUCCESS: {
      const shouldUpdateState = payload.shouldUpdateState;

      if (!shouldUpdateState) {
        return R.mergeDeepRight(state, {
          files: {
            loading: false,
          },
        });
      }

      return R.mergeDeepRight(state, {
        files: {
          data: R.propOr([], 'rows', payload),
          lastRow: R.propOr(0, 'lastRow', payload),

          loading: false,
        },
      });
    }
    case actionTypes.FETCH_FILES_ERROR: {
      return R.mergeDeepRight(state, { files: { error: payload } });
    }

    case actionTypes.SET_FILES_TABLE_FILTER: {
      return R.mergeRight(state, {
        files: { ...state.files, filterModel: payload },
      });
    }
    case actionTypes.SET_FILES_TABLE_SORT: {
      return R.mergeRight(state, {
        files: { ...state.files, sortModel: payload },
      });
    }
    case actionTypes.SET_FILES_SHOW_CANCEL: {
      return R.mergeDeepRight(state, {
        files: { showCancel: !state.files.showCancel },
      });
    }

    case actionTypes.SET_FILES_SELECTED_NODES: {
      return R.mergeDeepRight(state, {
        files: { selectedNodes: payload },
      });
    }
    default:
      return state;
  }
};

export default FilesReducer;
