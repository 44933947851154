import { RowNode } from 'ag-grid-community';
import * as R from 'ramda';

import { ExploreTable, ModelName } from 'utils/enum';
import * as actionTypes from './ExploreActionsTypes';

export const STATE_KEY = 'explore';

export type BlockModalInfo = {
  modalInfo: Record<string, any>;
  error: string;
  loading: boolean;
  modelName: ModelName;
  db_id: [];
  type: string;
  isMainBlockModal: boolean;
  isMultiple: boolean;
  blockId: string;
};

export type BlocksModalInfo = {
  showModals: boolean;
  data: BlockModalInfo[][];
};

export type BLockModalInfoType = {
  loading: boolean;
  error: string;
  modelName: ModelName | '';
  currentModalIdLoading: null | number;
  modalInfo: {
    modelName: ModelName;
    currentModelId: number;
    modalData: Record<string, any>;
  }[];
};

export type InitialTableStateTypes = {
  data: Record<string, any>[];
  error: boolean | string;
  loading: boolean;
  filterModel: Record<string, any>;
  lastRow: number;
  selectedNodes: Record<string, any>[];
  blocksModalInfo: BlocksModalInfo;
  blockModalInfo: BLockModalInfoType;
};

const initialTableState: InitialTableStateTypes = {
  data: [],
  error: false,
  loading: false,
  filterModel: {},
  lastRow: 0,
  selectedNodes: [],
  blocksModalInfo: {
    showModals: false,
    data: [],
  },
  blockModalInfo: {
    loading: false,
    error: '',
    modelName: '',
    modalInfo: [],
    currentModalIdLoading: null,
  },
};

export type InitialExploreStateType = {
  BFD: {
    loading: boolean;
    data: Record<string, any>;
    error: boolean;
  };
  orientingTable: {
    loading: boolean;
    data: RowNode[];
    error: boolean;
  };
  activesControlsOfDiagram: {
    leftPanel: string[];
    rightPanel: string[];
  };
  tables: {
    [ExploreTable.KBRA]: InitialTableStateTypes;
    [ExploreTable.KMAT]: InitialTableStateTypes;
    [ExploreTable.KPRO]: InitialTableStateTypes;
    [ExploreTable.KMEA]: InitialTableStateTypes;
  };
  activeTable: ExploreTable;
  blocksModalInfo: {
    showModals: boolean;
    data: Record<string, any>[];
  };
  blockModalInfo: {
    loading: boolean;
    error: string;
    modelName: ModelName;
    modalInfo: Record<string, any>[];
    currentModalIdLoading: number | null;
  };
};

const initialState = {
  BFD: {
    loading: false,
    data: {},
    error: false,
  },
  orientingTable: {
    loading: false,
    data: [],
    error: false,
  },
  activesControlsOfDiagram: {
    leftPanel: [],
    rightPanel: [''], // for default set ['types' etc]
  },
  tables: {
    [ExploreTable.KBRA]: {
      ...initialTableState,
    },
    [ExploreTable.KMAT]: {
      ...initialTableState,
    },
    [ExploreTable.KPRO]: {
      ...initialTableState,
    },
    [ExploreTable.KMEA]: {
      ...initialTableState,
    },
  },
  activeTable: ExploreTable.KMAT,
  blocksModalInfo: {
    showModals: false,
    data: [],
  },
  blockModalInfo: {
    loading: false,
    error: '',
    modelName: '',
    modalInfo: [],
    currentModalIdLoading: null,
  },
};

const ExploreReducer = (
  state = initialState,
  action: { type: string; payload: any },
) => {
  const { payload } = action;

  switch (action.type) {
    case actionTypes.FETCH_BFD_START: {
      return R.mergeRight(state, {
        BFD: { data: {}, loading: true, error: false },
      });
    }
    case actionTypes.FETCH_BFD_SUCCESS: {
      return R.mergeRight(state, {
        BFD: {
          data: payload?.result,
          loading: false,
          error: false,
        },
      });
    }
    case actionTypes.FETCH_BFD_ERROR: {
      return R.mergeRight(state, {
        BFD: { data: {}, error: payload, loading: false },
      });
    }

    case actionTypes.SET_INITIAL_BLOCKS_MODAL_INFO: {
      return {
        ...state,
        blocksModalInfo: {
          showModals: true,
          data: payload,
        },
      };
    }
    case actionTypes.FETCH_BLOCKS_MODAL_INFO_START: {
      const updatedData = state.blocksModalInfo.data.map(
        (block: BlockModalInfo[][], index) => {
          if (index === payload.rootIndex) {
            return block.map((x, deepIndex) => {
              if (deepIndex === payload.deepIndex) {
                return { ...x, loading: true };
              }
              return x;
            });
          }
          return block;
        },
      );
      return {
        ...state,
        blocksModalInfo: {
          ...state.blocksModalInfo,
          data: updatedData,
        },
      };
    }
    case actionTypes.FETCH_BLOCKS_MODAL_INFO_ERROR: {
      const updatedData = state.blocksModalInfo.data.map(
        (block: BlockModalInfo[][], index) => {
          if (index === payload.rootIndex) {
            return block.map((x, deepIndex) => {
              if (deepIndex === payload.deepIndex) {
                return { ...x, error: payload.error, loading: false };
              }
              return x;
            });
          }
          return block;
        },
      );
      return {
        ...state,
        blocksModalInfo: {
          ...state.blocksModalInfo,
          data: updatedData,
        },
      };
    }
    case actionTypes.FETCH_BLOCKS_MODAL_INFO_SUCCESS: {
      const updatedData = state.blocksModalInfo.data.map(
        (block: BlockModalInfo[][], index) => {
          if (index === payload.rootIndex) {
            return block.map((x, deepIndex) => {
              if (deepIndex === payload.deepIndex) {
                return { ...x, modalInfo: payload.modalInfo, loading: false };
              }
              return x;
            });
          }
          return block;
        },
      );
      return {
        ...state,
        blocksModalInfo: {
          ...state.blocksModalInfo,
          data: updatedData,
        },
      };
    }
    case actionTypes.CLEAR_BLOCKS_MODAL_INFO: {
      return {
        ...state,
        blocksModalInfo: {
          showModals: false,
          data: [],
        },
      };
    }
    case actionTypes.FETCH_BLOCKS_MODAL_INFO_START: {
      return { ...state, blocksModalInfo: payload };
    }
    case actionTypes.FETCH_BLOCKS_MODAL_INFO_SUCCESS: {
      return { ...state, blocksModalInfo: payload };
    }

    case actionTypes.SET_ACTIVE_TABLE: {
      return {
        ...state,
        activeTable: payload,
      };
    }

    case actionTypes.FETCH_BLOCK_MODAL_INFO_START: {
      return {
        ...state,
        blockModalInfo: {
          ...state.blockModalInfo,
          loading: true,
          currentModalIdLoading: payload,
        },
      };
    }
    case actionTypes.FETCH_BLOCK_MODAL_INFO_ERROR: {
      return {
        ...state,
        blockModalInfo: {
          ...state.blockModalInfo,
          loading: false,
          error: payload,
          currentModalIdLoading: null,
        },
      };
    }
    case actionTypes.FETCH_BLOCK_MODAL_INFO_SUCCESS: {
      const modalInfo = [...state.blockModalInfo.modalInfo, payload];

      return {
        ...state,
        blockModalInfo: {
          ...state.blockModalInfo,
          loading: false,
          error: '',
          modalInfo,
          currentModalIdLoading: null,
        },
      };
    }
    case actionTypes.CLEAR_BLOCK_MODAL_INFO: {
      return {
        ...state,
        blockModalInfo: initialState.blockModalInfo,
      };
    }

    case actionTypes.SET_ACTIVE_LEFT_PANEL: {
      return state;
      // return R.mergeDeepRight(state, {
      //   activesControlsOfDiagram: { leftPanel: [payload] },
      // });
    }
    case actionTypes.SET_ACTIVE_RIGHT_PANEL: {
      return state;
      // const {
      //   activesControlsOfDiagram: { rightPanel: activesRight },
      // } = state;
      // return activesRight.includes(payload)
      //   ? R.mergeDeepRight(state, {
      //       activesControlsOfDiagram: {
      //         rightPanel: activesRight.filter((a) => a !== payload),
      //       },
      //     })
      //   : R.mergeDeepRight(state, {
      //       activesControlsOfDiagram: {
      //         rightPanel: [...activesRight, payload],
      //       },
      //     });
    }
    case actionTypes.SET_DEFAULT_ACTIVE_PANELS: {
      return R.mergeDeepRight(state, {
        activesControlsOfDiagram: {
          leftPanel: [],
          rightPanel: ['types'],
        },
      });
    }

    case actionTypes.FETCH_ORIENTING_TABLE_START: {
      return R.mergeDeepRight(state, { orientingTable: { loading: true } });
    }
    case actionTypes.FETCH_ORIENTING_TABLE_SUCCESS: {
      return R.mergeDeepRight(state, {
        orientingTable: {
          data: payload,
          loading: false,
        },
      });
    }
    case actionTypes.FETCH_ORIENTING_TABLE_ERROR: {
      return R.mergeDeepRight(state, { orientingTable: { error: payload } });
    }

    case actionTypes.FETCH_BFD_TABLE_START: {
      return R.mergeDeepRight(state, {
        tables: { [payload.modelApi]: { loading: true } },
      });
    }
    case actionTypes.FETCH_BFD_TABLE_SUCCESS: {
      const shouldUpdateState = payload.shouldUpdateState;

      if (!shouldUpdateState) {
        return R.mergeDeepRight(state, {
          tables: {
            [payload.modelApi]: {
              loading: false,
            },
          },
        });
      }

      return R.mergeDeepRight(state, {
        tables: {
          [payload.modelApi]: {
            data: payload?.data?.rows || [],
            lastRow: payload?.data?.lastRow || 0,
            loading: false,
          },
        },
      });
    }
    case actionTypes.FETCH_BFD_TABLE_ERROR: {
      return R.mergeDeepRight(state, {
        tables: {
          [payload.modelApi]: { error: payload.error },
        },
      });
    }

    case actionTypes.SET_EXPLORE_TABLE_SELECTED_NODES: {
      return R.mergeDeepRight(state, {
        tables: {
          [payload.modelApi]: {
            selectedNodes: payload.nodes,
          },
        },
      });
    }

    default:
      return state;
  }
};

export default ExploreReducer;
