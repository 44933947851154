import { HistogramSettingsType } from 'modules/visualize/VisualizeReducer';
import { getFixedNumber } from 'utils/utils';
import { HistogramDataGroupedByYKeyType } from '../HistogramChartPlot';
import { HistogramChartTypeEnum } from '../models/histogramChartModels';
import { HistogramChartResponse } from '../models/histogramChartResponseModel';

export const getHistogramChartTypeByColumnName = (
  modelDefinitions: Array<Array<string>> | null = null,

  columnName: string,
): HistogramChartTypeEnum => {
  if (!modelDefinitions) {
    return HistogramChartTypeEnum.CATEGORICAL;
  }

  const columnType = modelDefinitions.find(
    ([column]) => column === columnName,
  )?.[1];

  return columnType === 'str'
    ? HistogramChartTypeEnum.CATEGORICAL
    : HistogramChartTypeEnum.CONTINIOUS;
};

export const getHistogramDataset = (
  histogramChartType: HistogramChartTypeEnum,
  chartData: HistogramChartResponse,
  settings: {
    minAxis: number;
    maxAxis: number;
    binSize: number;
  },
) => {
  const { minAxis, maxAxis, binSize } = settings;

  const isCategoricalChartType =
    histogramChartType === HistogramChartTypeEnum.CATEGORICAL;

  const options = {
    isCategorical: isCategoricalChartType,
  };

  if (isCategoricalChartType) {
    const dataGroupedByYKey = chartData.dots
      .filter((d) => d.y)
      .reduce<HistogramDataGroupedByYKeyType>((acc, val) => {
        const yKey = val.y;
        const matchedGroup = acc[yKey];

        if (matchedGroup) {
          return {
            ...acc,
            [yKey]: {
              x: matchedGroup.x + 1,
              meta: [...matchedGroup.meta, val.meta],
            },
          };
        } else {
          return {
            ...acc,
            [yKey]: {
              x: 1,
              meta: [val.meta],
            },
          };
        }
      }, {});

    return {
      options,
      data: Object.keys(dataGroupedByYKey).map((key) => ({
        y: key,
        x: dataGroupedByYKey[key].x,
        meta: dataGroupedByYKey[key].meta,
      })),
      dataGroupedByYKey,
    };
  }
  const groupsYCount =
    maxAxis === minAxis
      ? 1
      : Math.ceil(
          (maxAxis < 0 || minAxis < 0
            ? Math.abs(maxAxis) + Math.abs(minAxis)
            : Math.abs(maxAxis) - Math.abs(minAxis)) / binSize,
        );

  const dataToProcess = chartData.dots.filter((d) => typeof d.y !== null);

  if (binSize === 0 || dataToProcess.length === 0) {
    return {
      options,
      data: [],
      dataGroupedByYKey: {},
    };
  }

  let startWith = minAxis;
  let endWith = binSize + minAxis;
  let iteration = 1;

  const data = [];
  let dataGroupedByYKey: HistogramDataGroupedByYKeyType = {};

  while (groupsYCount >= iteration) {
    const groupY = dataToProcess.filter((x) =>
      iteration === groupsYCount
        ? x.y <= endWith && x.y >= startWith
        : x.y < endWith && x.y >= startWith,
    );

    const x = groupY.length;
    const meta = groupY.map((x) => x.meta);

    data.push({
      x,
      y: getFixedNumber(endWith, 3),
      meta,
    });

    dataGroupedByYKey = {
      ...dataGroupedByYKey,
      [getFixedNumber(endWith, 3)]: {
        meta,
        x,
      },
    };

    startWith = +endWith.toFixed(4);
    endWith = +(binSize + endWith).toFixed(4);
    iteration++;

    if (iteration === groupsYCount) {
      endWith = maxAxis;
    }
  }

  return {
    options,
    data,
    dataGroupedByYKey,
  };
};

const getHistogramActiveValuesData = (
  chartData: HistogramChartResponse,
  chartSettings: HistogramSettingsType,
) => {
  const filteredDotsByAxisSettings = chartData.dots.filter(
    (x) =>
      x.y >= (chartSettings?.minAxis ?? 0) &&
      x.y <= (chartSettings?.maxAxis ?? 0),
  );

  const missedValues = filteredDotsByAxisSettings.filter((x) => x.y === null);
  const activeValues = filteredDotsByAxisSettings.filter((x) => x.y !== null);

  const countOfActiveValues = activeValues.length;

  const orderedActiveValues = activeValues.sort((a, b) => a.y - b.y);

  return {
    missedValues,
    activeValues,
    countOfActiveValues,
    filteredDotsByAxisSettings,
    orderedActiveValues,
  };
};

export const calculateHistogramStatistics = (
  chartData: HistogramChartResponse,
  chartSettings: HistogramSettingsType,
) => {
  const initialStatistics = {
    n: 0,
    nMissing: 0,
    mean: 0,
    cv: 0,
    median: 0,
    robustStdDev: 0,
    robustCv: 0,
    signalTarget: 0,
    UCL: 0,
    LCL: 0,
    testForNormally: 0,
    intermediateSum: 0,
  };

  const chartType = chartSettings?.histogramChartType;

  if (!chartData?.dots || chartData?.dots?.length === 0 || !chartType) {
    return initialStatistics;
  }

  const {
    filteredDotsByAxisSettings,
    missedValues,
    activeValues,
    orderedActiveValues,
    countOfActiveValues,
  } = getHistogramActiveValuesData(chartData, chartSettings);

  initialStatistics.n = filteredDotsByAxisSettings.length;

  initialStatistics.nMissing = missedValues.length;

  if (chartType === HistogramChartTypeEnum.CATEGORICAL) {
    return initialStatistics;
  }

  // START: calculate mean value
  const sumOfActiveValues = activeValues.reduce(
    (acc, val) => (acc += val.y),
    0,
  );

  initialStatistics.mean =
    sumOfActiveValues === 0 || countOfActiveValues === 0
      ? 0
      : Number((sumOfActiveValues / countOfActiveValues).toFixed(2));
  // END: calculate mean value

  // START: calculate cv value
  const intermediateSum = activeValues
    .map((x) => Math.pow(x.y - initialStatistics.mean, 2))
    .reduce((acc, val) => (acc += val), 0);
  const stdev =
    intermediateSum === 0 || countOfActiveValues === 0
      ? 0
      : Math.pow(intermediateSum / countOfActiveValues, 0.5);
  initialStatistics.cv =
    initialStatistics.mean === 0 || stdev === 0
      ? 0
      : Number(((100 * stdev) / initialStatistics.mean).toFixed(0));

  initialStatistics.intermediateSum = intermediateSum;
  // END: calculate cv value

  if (activeValues.length === 0) {
    return initialStatistics;
  }

  // START: calculate median value
  const isEvenCountOfActiveValues = orderedActiveValues.length % 2 === 0;

  // or on the middle
  const secondValueInTheMiddle =
    orderedActiveValues[Math.floor(orderedActiveValues.length / 2)]?.y ?? 0;

  const firstValueInTheMiddle =
    orderedActiveValues[Math.floor(orderedActiveValues.length / 2) - 1]?.y ?? 0;

  const median =
    orderedActiveValues.length === 0
      ? 0
      : isEvenCountOfActiveValues
      ? secondValueInTheMiddle === 0 || firstValueInTheMiddle === 0
        ? 0
        : (secondValueInTheMiddle + firstValueInTheMiddle) / 2
      : orderedActiveValues[Math.floor(orderedActiveValues.length / 2)]?.y;

  initialStatistics.median = Number(median.toFixed(2));
  // END: calculate median value

  return initialStatistics;
};

export const calculateBoxPlotData = (
  chartData: HistogramChartResponse,
  chartSettings: HistogramSettingsType,
) => {
  const { orderedActiveValues, countOfActiveValues } =
    getHistogramActiveValuesData(chartData, chartSettings);

  const { median, intermediateSum, mean } = calculateHistogramStatistics(
    chartData,
    chartSettings,
  );

  const countOfValuesInQuerter = Math.round(countOfActiveValues / 4);

  const boxTop = orderedActiveValues[countOfValuesInQuerter * 3]?.y ?? 0;
  const boxBottom = orderedActiveValues[countOfValuesInQuerter]?.y ?? 0;
  const min = orderedActiveValues[0]?.y ?? 0;
  const max = orderedActiveValues.at(-1)?.y ?? 0;

  const stdev = Math.pow(intermediateSum / (countOfActiveValues - 1), 0.5);

  const tinv =
    countOfActiveValues <= 2
      ? 12.706
      : countOfActiveValues <= 3
      ? 4.303
      : countOfActiveValues <= 4
      ? 3.182
      : countOfActiveValues <= 5
      ? 2.776
      : countOfActiveValues <= 15
      ? 2.145
      : countOfActiveValues <= 30
      ? 2.045
      : countOfActiveValues <= 120
      ? 1.98
      : 1.96;

  const ME =
    countOfActiveValues === 0
      ? 0
      : (tinv * stdev) / Math.pow(countOfActiveValues, 0.5);

  const diamondTop = mean + ME;
  const diamondBottom = mean - ME;

  return {
    median,
    min,
    max,
    boxTop,
    boxBottom,
    diamondTop,
    diamondBottom,
    diamondCenter: mean,
  };
};
