export const FETCH_BFD_START = 'FETCH_BFD_START';
export const FETCH_BFD_SUCCESS = 'FETCH_BFD_SUCCESS';
export const FETCH_BFD_ERROR = 'FETCH_BFD_ERROR';

export const FETCH_ORIENTING_TABLE_START = 'FETCH_ORIENTING_TABLE_START';
export const FETCH_ORIENTING_TABLE_SUCCESS = 'FETCH_ORIENTING_TABLE_SUCCESS';
export const FETCH_ORIENTING_TABLE_ERROR = 'FETCH_ORIENTING_TABLE_ERROR';

export const SET_ACTIVE_LEFT_PANEL = 'SET_ACTIVE_LEFT_PANEL';
export const SET_ACTIVE_RIGHT_PANEL = 'SET_ACTIVE_RIGHT_PANEL';
export const SET_DEFAULT_ACTIVE_PANELS = 'SET_DEFAULT_ACTIVE_PANELS';

export const SET_ACTIVE_TABLE = 'SET_ACTIVE_TABLE';

export const FETCH_BFD_TABLE_START = 'FETCH_BFD_TABLE_START';
export const FETCH_BFD_TABLE_SUCCESS = 'FETCH_BFD_TABLE_SUCCESS';
export const FETCH_BFD_TABLE_ERROR = 'FETCH_BFD_TABLE_ERROR';

export const SET_INITIAL_BLOCKS_MODAL_INFO = 'SET_INITIAL_BLOCKS_MODAL_INFO';
export const CLEAR_BLOCKS_MODAL_INFO = 'CLEAR_BLOCKS_MODAL_INFO';
export const FETCH_BLOCKS_MODAL_INFO_START = 'FETCH_BLOCKS_MODAL_INFO_START';
export const FETCH_BLOCKS_MODAL_INFO_SUCCESS =
  'FETCH_BLOCKS_MODAL_INFO_SUCCESS';
export const FETCH_BLOCKS_MODAL_INFO_ERROR = 'FETCH_BLOCKS_MODAL_INFO_ERROR';

export const CLEAR_BLOCK_MODAL_INFO = 'CLEAR_BLOCK_MODAL_INFO';
export const FETCH_BLOCK_MODAL_INFO_START = 'FETCH_BLOCK_MODAL_INFO_START';
export const FETCH_BLOCK_MODAL_INFO_SUCCESS = 'FETCH_BLOCK_MODAL_INFO_SUCCESS';
export const FETCH_BLOCK_MODAL_INFO_ERROR = 'FETCH_BLOCK_MODAL_INFO_ERROR';

export const SET_EXPLORE_TABLE_SELECTED_NODES =
  'SET_EXPLORE_TABLE_SELECTED_NODES';
