import * as R from 'ramda';

import * as actionTypes from 'modules/GlobalActionsTypes';

export const STATE_KEY = 'measurementTypes';

const initialState = {
  measurementTypes: {
    loading: false,
    data: [],
    error: false,
    filterModel: {},
    showCancel: false,
    selectedNodes: [],
    sortModel: [],
  },
};

const MeasurementTypesReducer = (state = initialState, action: any) => {
  const { payload } = action;

  switch (action.type) {
    case actionTypes.FETCH_MEASUREMENT_TYPES_START: {
      return R.mergeDeepRight(state, {
        measurementTypes: { loading: true },
      });
    }
    case actionTypes.FETCH_MEASUREMENT_TYPES_SUCCESS: {
      const shouldUpdateState = payload.shouldUpdateState;

      if (!shouldUpdateState) {
        return R.mergeDeepRight(state, {
          measurementTypes: {
            loading: false,
          },
        });
      }

      return R.mergeDeepRight(state, {
        measurementTypes: {
          data: R.propOr([], 'rows', payload),
          lastRow: R.propOr(0, 'lastRow', payload),

          loading: false,
        },
      });
    }
    case actionTypes.FETCH_MEASUREMENT_TYPES_ERROR: {
      return R.mergeDeepRight(state, { measurementTypes: { error: payload } });
    }

    case actionTypes.SET_MEASUREMENT_TYPES_TABLE_FILTER: {
      return R.mergeRight(state, {
        measurementTypes: { ...state.measurementTypes, filterModel: payload },
      });
    }
    case actionTypes.SET_MEASUREMENT_TYPES_TABLE_SORT: {
      return R.mergeRight(state, {
        measurementTypes: { ...state.measurementTypes, sortModel: payload },
      });
    }
    case actionTypes.SET_MEASUREMENT_TYPES_SHOW_CANCEL: {
      return R.mergeDeepRight(state, {
        measurementTypes: { showCancel: !state.measurementTypes.showCancel },
      });
    }

    case actionTypes.SET_MEASUREMENT_TYPES_SELECTED_NODES: {
      return R.mergeDeepRight(state, {
        measurementTypes: { selectedNodes: payload },
      });
    }
    default:
      return state;
  }
};

export default MeasurementTypesReducer;
