import * as R from 'ramda';

import * as actionTypes from 'modules/GlobalActionsTypes';
import { getEnrichedWMEARows } from './utils';

export const STATE_KEY = 'measurements';

const initialState = {
  measurements: {
    loading: false,
    data: [],
    error: false,
    filterModel: {},
    sortModel: [],
    showCancel: false,
    selectedNodes: [],
  },
};

const MeasurementsReducer = (state = initialState, action: any) => {
  const { payload } = action;

  switch (action.type) {
    case actionTypes.FETCH_MEASUREMENTS_START: {
      return R.mergeDeepRight(state, { measurements: { loading: true } });
    }
    case actionTypes.FETCH_MEASUREMENTS_SUCCESS: {
      const shouldUpdateState = payload.shouldUpdateState;

      if (!shouldUpdateState) {
        return R.mergeDeepRight(state, {
          measurements: {
            loading: false,
          },
        });
      }

      const rows = R.propOr([], 'rows', payload);
      const enrichedRows = getEnrichedWMEARows(rows);

      return R.mergeDeepRight(state, {
        measurements: {
          data: enrichedRows,
          lastRow: R.propOr(0, 'lastRow', payload),
          loading: false,
        },
      });
    }
    case actionTypes.FETCH_MEASUREMENTS_ERROR: {
      return R.mergeDeepRight(state, { processes: { error: payload } });
    }

    case actionTypes.SET_MEASUREMENTS_TABLE_FILTER: {
      return R.mergeRight(state, {
        measurements: { ...state.measurements, filterModel: payload },
      });
    }
    case actionTypes.SET_MEASUREMENTS_TABLE_SORT: {
      return R.mergeRight(state, {
        measurements: { ...state.measurements, sortModel: payload },
      });
    }
    case actionTypes.SET_MEASUREMENTS_SHOW_CANCEL: {
      return R.mergeDeepRight(state, {
        measurements: { showCancel: !state.measurements.showCancel },
      });
    }
    case actionTypes.SET_MEASUREMENTS_SELECTED_NODES: {
      return R.mergeDeepRight(state, {
        measurements: { selectedNodes: payload },
      });
    }
    default:
      return state;
  }
};

export default MeasurementsReducer;
