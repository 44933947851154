import { useRef } from 'react';
import styled from 'styled-components';
import * as R from 'ramda';

import { SearchOutlined } from '@mui/icons-material';

import { ModelName } from 'utils/enum';
import HoverIconButton from 'components/HoverIconButton';
import ModelIcon from 'components/ModelIcon';
import colors from 'theme/patterns/colors';

interface SectionRelatedModelProps
  extends React.ComponentPropsWithoutRef<'div'> {
  relatedModelData: Record<string, Array<any>>;
  modelName: ModelName;
  handleClickRelatedButton: (modelName: ModelName, type: string) => void;
  title: string;
}

const SectionRelatedModel = ({
  relatedModelData,
  modelName,
  title,
  handleClickRelatedButton,
  ...rest
}: SectionRelatedModelProps) => {
  const buttonRef = useRef(null);

  return (
    <Wrap {...rest}>
      <Header>
        <ModelIcon modelName={modelName} fontSize="default" />
        <Name>{title}</Name>
        <TypesCount>({R.keys(relatedModelData).length} Types)</TypesCount>
      </Header>
      <ButtonsWrap>
        {R.keys(relatedModelData).map((type: string) => (
          <HoverIconButton
            key={type}
            text={`${type} (${relatedModelData[type].length})`}
            hoverIcon={<SearchOutlined />}
            customColors={colors[modelName]}
            ref={buttonRef}
            onClick={() => handleClickRelatedButton(modelName, type)}
          />
        ))}
      </ButtonsWrap>
    </Wrap>
  );
};

const Wrap = styled.div`
  margin-top: 25px;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  font-size: 16px;

  .MuiSvgIcon-root {
    color: black !important;
  }
`;

const Name = styled.span`
  margin-left: 7px;

  font-weight: 600;
`;

const TypesCount = styled.span`
  margin-left: 5px;
`;

const ButtonsWrap = styled.div`
  flex-wrap: wrap;

  .MuiButtonBase-root {
    margin: 10px 10px 0 0;
  }
`;

export default SectionRelatedModel;
