import * as R from 'ramda';

export const t = {
  white: { value: '#FFF', name: 'White' },
  black: { value: '#000', name: 'Black' },
};

const colorScheme = {
  primary: { value: '#433fb5', name: 'Primary' },
  green: { value: '#4caf50', name: 'Green' },
  red: { value: '#cc0000', name: 'Red' },
  white: { value: '#fff', name: 'White' },
  black: { value: 'rgba(0,0,0,0.87)', name: 'Black' },
  grey: { value: '#e2e2e2', name: 'Grey' },
  grey100: { value: '#f5f5f5', name: 'Grey100' },
  silver: { value: '#eeeeee', name: 'Silver' },
  lightGrey: { value: '#b0b0b0', name: 'Light Grey' },
  darkGrey: { value: '#6a6a6a', name: 'Dark Grey' },
  blue: { value: '#6699cc', name: 'Blue' },
  orange: { value: '#f0ad4e', name: 'Orange' },
  info: { value: '#0275d8', name: 'Light Blue' },
  material: {
    value: {
      color: '#B71C1C',
      text: '#A01616',
      bgc: 'rgba(183, 28, 28, 0.04)',
    },
    name: 'Material Model',
  },
  process: {
    value: {
      color: '#FF9800',
      text: '#A06000',
      bgc: 'rgba(255, 152, 0, 0.04)',
    },
    name: 'Process Model',
  },
  measurement: {
    value: {
      color: '#E65100',
      text: '#BC4403',
      bgc: 'rgba(230, 81, 0, 0.04)',
    },
    name: 'Measurement Model',
  },
};

export const getColor = (colorKey: string) =>
  R.path([colorKey, 'value'], colorScheme);

const getColorsMap = () =>
  R.compose(
    R.reduce(
      (acc: any, [key, scheme]: any) => R.assoc(key, scheme.value, acc),
      {},
    ),
    R.toPairs,
  )(colorScheme);

const colors = getColorsMap();

export default colors;
