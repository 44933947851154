import { RowNode } from 'ag-grid-community';

import axios from 'axios';
import axiosInstance from 'config/AxiosConfig';
import { Dispatch } from 'redux';
import { API_ENDPOINTS, ExploreTable, ModelName } from 'utils/enum';
import { capitalizedFirstLetter } from 'utils/utils';
import * as actionTypes from './ExploreActionsTypes';

interface fetchBFDProps {
  modelName: ModelName;
  params: Record<string, any>;
}

type SetBlockModalDataType = {
  modalInfo: {
    modelName: ModelName;
    currentModelId: number;
  };
};

export const fetchBFD =
  ({ modelName, params }: fetchBFDProps) =>
  async (dispatch: Dispatch) => {
    dispatch({ type: actionTypes.FETCH_BFD_START });
    try {
      const { data } = await axiosInstance.post(
        `/${API_ENDPOINTS.explore.bfd}/${
          modelName.charAt(0).toUpperCase() + modelName.slice(1)
        }`,
        params,
      );

      if (data?.error) {
        return dispatch({
          type: actionTypes.FETCH_BFD_ERROR,
          payload: data.error,
        });
      }
      return dispatch({
        type: actionTypes.FETCH_BFD_SUCCESS,
        payload: data,
      });
    } catch (err) {
      dispatch({ type: actionTypes.FETCH_BFD_ERROR, payload: err.message });
      console.error(err.message);
    }
  };

interface fetchHeaderTableProps {
  params?: Record<string, any>;
  modelApi: string;
}

export const fetchOrientingTable =
  ({ params = {}, modelApi }: fetchHeaderTableProps) =>
  async (dispatch: Dispatch) => {
    dispatch({ type: actionTypes.FETCH_ORIENTING_TABLE_START });
    try {
      const { data } = await axiosInstance.post(
        `/${API_ENDPOINTS.models.getTable}/${modelApi}`,
        params,
      );

      return dispatch({
        type: actionTypes.FETCH_ORIENTING_TABLE_SUCCESS,
        payload: data.rows,
      });
    } catch (err) {
      dispatch({
        type: actionTypes.FETCH_ORIENTING_TABLE_ERROR,
        payload: err.message,
      });
      console.log(err.message);
    }
  };

interface fetchExploreTableProps {
  modelApi: ExploreTable;
  params: Record<string, any>;
  shouldUpdateState?: boolean;
}

export const fetchExploreTable =
  ({
    modelApi,
    params = {},
    shouldUpdateState = true,
  }: fetchExploreTableProps) =>
  async (dispatch: Dispatch) => {
    dispatch({
      type: actionTypes.FETCH_BFD_TABLE_START,
      payload: { modelApi },
    });
    try {
      const { data } = await axiosInstance.post(
        `/${API_ENDPOINTS.explore.table}/${modelApi}`,
        params,
      );
      data.lastRow = data.rows?.length > 0 ? data.lastRow + 1 : 0;

      if (data?.error) {
        return dispatch({
          type: actionTypes.FETCH_BFD_TABLE_ERROR,
          payload: { modelApi, error: data.error },
        });
      }
      return dispatch({
        type: actionTypes.FETCH_BFD_TABLE_SUCCESS,
        payload: {
          modelApi,
          data,
          shouldUpdateState,
        },
      });
    } catch (err) {
      dispatch({
        type: actionTypes.FETCH_BFD_TABLE_ERROR,
        payload: { modelApi, error: err.message },
      });
      console.error(err.message);
    }
  };

export const setBlockModalInfo =
  ({ modalInfo }: SetBlockModalDataType) =>
  async (dispatch: Dispatch) => {
    dispatch({
      type: actionTypes.FETCH_BLOCK_MODAL_INFO_START,
      payload: modalInfo.currentModelId,
    });

    try {
      const { data: modalData } = await axiosInstance.get(
        `/${API_ENDPOINTS.models.getViewModel}/${capitalizedFirstLetter(
          modalInfo.modelName,
        )}/${modalInfo.currentModelId}`,
      );

      if (modalData) {
        dispatch({
          type: actionTypes.FETCH_BLOCK_MODAL_INFO_SUCCESS,
          payload: {
            modalData,
            ...modalInfo,
          },
        });
      }
    } catch (err) {
      console.log(err.message);
      if (axios.isAxiosError(err)) {
        const errorMessage = (err.response?.data as { error: string }).error;
        dispatch({
          type: actionTypes.FETCH_BLOCK_MODAL_INFO_ERROR,
          payload: errorMessage,
        });
        return err.response?.data;
      } else {
        dispatch({
          type: actionTypes.FETCH_BLOCK_MODAL_INFO_ERROR,
          payload: err.message,
        });
      }
    }
  };
export const clearBlockModalData = () => ({
  type: actionTypes.CLEAR_BLOCK_MODAL_INFO,
});

export type InitialBlocksModalInfoPayloadType = {
  db_id: number[];
  modelName: ModelName;
  type: string;
  isMainBlockModal: boolean;
  isMultiple: boolean;
}[][];

export const fetchBlockModalInfo =
  ({
    modelName,
    indexesToUpdate,
    db_ids,
  }: {
    modelName: ModelName;
    indexesToUpdate: { rootIndex: number; deepIndex: number };
    db_ids: string[];
  }) =>
  async (dispatch: Dispatch) => {
    dispatch({
      type: actionTypes.FETCH_BLOCKS_MODAL_INFO_START,
      payload: indexesToUpdate,
    });

    const isMultipleIds = db_ids.length > 1;

    if (isMultipleIds) {
      try {
        const { data: modalData } = await axiosInstance.post(
          `/${
            API_ENDPOINTS.models.getMultipleViewModel
          }/${capitalizedFirstLetter(modelName)}`,
          { db_ids },
        );

        return dispatch({
          type: actionTypes.FETCH_BLOCKS_MODAL_INFO_SUCCESS,
          payload: { ...indexesToUpdate, modalInfo: modalData },
        });
      } catch (err) {
        console.log(err.message);
        if (axios.isAxiosError(err)) {
          return dispatch({
            type: actionTypes.FETCH_BLOCKS_MODAL_INFO_ERROR,
            payload: { ...indexesToUpdate, error: err.message },
          });
        } else {
          dispatch({
            type: actionTypes.FETCH_BLOCKS_MODAL_INFO_ERROR,
            payload: err.message,
          });
        }
      }
    }

    try {
      const { data: modalData } = await axiosInstance.get(
        `/${API_ENDPOINTS.models.getViewModel}/${capitalizedFirstLetter(
          modelName,
        )}/${db_ids[0]}`,
      );
      return dispatch({
        type: actionTypes.FETCH_BLOCKS_MODAL_INFO_SUCCESS,
        payload: { ...indexesToUpdate, modalInfo: modalData },
      });
    } catch (err) {
      console.log(err.message);
      if (axios.isAxiosError(err)) {
        return dispatch({
          type: actionTypes.FETCH_BLOCKS_MODAL_INFO_ERROR,
          payload: { ...indexesToUpdate, error: err.message },
        });
      } else {
        dispatch({
          type: actionTypes.FETCH_BLOCKS_MODAL_INFO_ERROR,
          payload: err.message,
        });
      }
    }
  };

export const setInitialBlocksModalInfo =
  (
    payload: {
      db_id: number[];
      modelName: ModelName;
      type: string;
      isMainBlockModal: boolean;
      isMultiple: boolean;
    }[][],
  ) =>
  (dispatch: Dispatch) =>
    dispatch({ type: actionTypes.SET_INITIAL_BLOCKS_MODAL_INFO, payload });
export const clearBlocksModalInfo = () => (dispatch: Dispatch) =>
  dispatch({ type: actionTypes.CLEAR_BLOCKS_MODAL_INFO });

export const setActiveLeftPanel = (activity: string) => (dispatch: Dispatch) =>
  dispatch({ type: actionTypes.SET_ACTIVE_LEFT_PANEL, payload: activity });
export const setActiveRightPanel = (activity: string) => (dispatch: Dispatch) =>
  dispatch({ type: actionTypes.SET_ACTIVE_RIGHT_PANEL, payload: activity });
export const setDefaultActivePanels = () => (dispatch: Dispatch) =>
  dispatch({ type: actionTypes.SET_DEFAULT_ACTIVE_PANELS });

export const setActiveTable = (table: ExploreTable) => (dispatch: Dispatch) =>
  dispatch({ type: actionTypes.SET_ACTIVE_TABLE, payload: table });

export const setExploreTableSelectedNodes = (
  modelApi: string,
  nodes: RowNode[],
) => ({
  type: `SET_EXPLORE_TABLE_SELECTED_NODES`,
  payload: { modelApi, nodes },
});
