import styled from 'styled-components';

import { Tooltip, Button, Typography } from '@mui/material/';
import ModalHeaderChip from 'components/Chips/ModalHeaderChip';
import { activityHistoryIcons } from 'components/Icons/ActivityHistoryIcons';
import DescriptionOutlined from '@mui/icons-material/DescriptionOutlined';
import { getActivityHistoryText, openNewTab } from 'utils/utils';

export enum ActivityHistoryAction {
  Created = 'Created',
  Updated = 'Updated',
  'Seen in' = 'Seen in',
  Voided = 'Voided',
  Inactivated = 'Inactivated',
  Reactivated = 'Reactivated',
}

export type DetailsMapType = {
  datetime: string;
  user_code: string;
  name_display: string;
  user_id: number;
  action: ActivityHistoryAction;
  context: string | null;
  file_id: number | null;
};

export const ActivityHistory = ({
  detailsMap,
}: {
  detailsMap: DetailsMapType[];
}) => (
  <>
    {detailsMap.length > 0 ? (
      detailsMap.map((activityHistory, index) => (
        <ActivityHistoryWrapper key={index}>
          <ModalHeaderChip
            label={activityHistory.datetime}
            style={{ borderRadius: 4, width: 120, height: 22 }}
          />

          <Tooltip
            title={activityHistory.name_display}
            placement="top"
            componentsProps={{
              tooltip: {
                style: { marginBottom: 6 },
              },
            }}
          >
            <CustomButtom
              color="inherit"
              style={{
                border: '1px solid #ebebeb',
              }}
            >
              {activityHistory.user_code}
            </CustomButtom>
          </Tooltip>

          <Tooltip
            title={getActivityHistoryText(
              activityHistory.action,
              activityHistory.context,
            )}
            placement="top"
            componentsProps={{
              tooltip: {
                style: { marginBottom: 6 },
              },
            }}
          >
            <CustomButtom
              style={{
                minWidth: 26,
                backgroundColor: '#fff',
              }}
            >
              {activityHistoryIcons[activityHistory.action].icon}
            </CustomButtom>
          </Tooltip>

          {activityHistory.file_id && (
            <CustomButtom
              variant="outlined"
              color="primary"
              style={{
                justifyContent: 'space-around',
                minWidth: 58,
                marginRight: 0,
              }}
              onClick={() =>
                openNewTab(`/program?db_id=${activityHistory.file_id}`)
              }
            >
              <DescriptionOutlined fontSize="small" style={{ fontSize: 16 }} />
              <FileIdWrapper>{activityHistory.file_id}</FileIdWrapper>
            </CustomButtom>
          )}
        </ActivityHistoryWrapper>
      ))
    ) : (
      <Typography variant="body1">
        No activity history information to display.
      </Typography>
    )}
  </>
);

const ActivityHistoryWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 5px 0;
`;

const CustomButtom = styled(Button)`
  font-size: 12px;
  font-weight: 600;
  text-transform: none;
  height: 22px;
  padding: 0;
  color: ${(props) => props.color ?? '#fff'};
  border-radius: 4px;
  margin: 0 5px;
`;

const FileIdWrapper = styled.span`
  display: inline-block;
  margin: 0 3px;
  text-overflow: ellipsis;
  overflow-x: hidden;
  white-space: nowrap;
  max-width: 80px;
`;
