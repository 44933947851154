import { StyledEngineProvider } from '@mui/material/styles';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles/';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { StrictMode, useEffect, useState } from 'react';
import { HeadProvider } from 'react-head';
import { useLocation } from 'react-router';
import { ThemeProvider } from 'styled-components';

import authRoutes from 'authRoutes';
import Drawer from 'components/Drawer';
import ScrollToTop from 'components/ScrollToTop/ScrollToTop';
import ModalEventContainer from 'containers/ModalEventContainer';
import ModalModelContainer from 'containers/ModalModelContainer';
import { fetchModelDefinitions } from 'modules/GlobalActions';
import { getModalEventData, getStackModalData } from 'modules/GlobalSelectors';
import { checkAuth, fetchUserInfo } from 'modules/auth/AuthActions';
import { getAccessToken, isAuthLoading } from 'modules/auth/AuthSelectors';
import { getTheme } from 'theme';
import { isUserInfoLoading } from './modules/auth/AuthSelectors';
import { materialDefaultTheme } from './theme/themes/material';

import ModalBlocksModelContainer from 'containers/ModalBlocksModelContainer';
import { getBlocksModalInfo } from 'modules/explore/ExploreSelectors';
import routes from './routes';

const { theme, GlobalStyle } = getTheme('base');

const pathListNotRequireAuth = [
  '/auth/confirmforgot',
  '/auth/forgot-password',
  '/auth/password-update-success',
  '/auth/password-reset-sent',
];
const isPathNotRequireAuth = (checkPathname: string) =>
  pathListNotRequireAuth.some((p: string): boolean =>
    checkPathname.includes(p),
  );

const App = () => {
  const dispatch = useAppDispatch();
  const [authIsChecked, setAuthIsChecked] = useState(false);
  const isAuth = useAppSelector(getAccessToken);
  const isAuthProcessing = useAppSelector(isAuthLoading);
  const isUserInfoFetching = useAppSelector(isUserInfoLoading);
  const stackModalData = useAppSelector(getStackModalData);
  const { showModals: showBlockModals } = useAppSelector(getBlocksModalInfo);
  const { open: isModalEventModelOpen } = useAppSelector(getModalEventData);
  const { pathname } = useLocation();
  const isFeedbackPage = pathname.includes('feedback');

  useEffect(() => {
    if (!isPathNotRequireAuth(pathname)) {
      dispatch(checkAuth());
      setAuthIsChecked(true);
    }
  }, [dispatch, pathname]);

  useEffect(() => {
    if (authIsChecked && isAuth && !isAuthProcessing && !isFeedbackPage) {
      dispatch(fetchModelDefinitions());
      !isUserInfoFetching && dispatch(fetchUserInfo());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authIsChecked, dispatch, isAuth, isAuthProcessing, isFeedbackPage]);

  return (
    <StrictMode>
      <HeadProvider>
        <ThemeProvider theme={theme}>
          <MuiThemeProvider theme={materialDefaultTheme}>
            <StyledEngineProvider injectFirst>
              <GlobalStyle />
              {!isAuth ? (
                <ScrollToTop>{authRoutes}</ScrollToTop>
              ) : (
                <Drawer>
                  <ScrollToTop>
                    {routes}
                    {stackModalData.showModal &&
                      !stackModalData.isModalOpenByQuery && (
                        <ModalModelContainer />
                      )}

                    {showBlockModals && <ModalBlocksModelContainer />}
                  </ScrollToTop>

                  {isModalEventModelOpen && <ModalEventContainer />}
                </Drawer>
              )}
            </StyledEngineProvider>
          </MuiThemeProvider>
        </ThemeProvider>
      </HeadProvider>
    </StrictMode>
  );
};

export default App;
