import { ReduxRouter } from '@lagunovsky/redux-react-router';
import { FrontloadProvider, createFrontloadState } from 'react-frontload';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor, history } from 'store/configureStore';

import App from './App';

const frontloadState = createFrontloadState.client({
  serverRenderedData: {},
  context: '',
  logging: false,
});

const AppContainer = () => (
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <ReduxRouter history={history} store={store}>
        <FrontloadProvider initialState={frontloadState}>
          <App />
        </FrontloadProvider>
      </ReduxRouter>
    </PersistGate>
  </Provider>
);

export default AppContainer;
