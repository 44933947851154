import * as R from 'ramda';

import * as actionTypes from 'modules/GlobalActionsTypes';

export const STATE_KEY = 'instruments';

const initialState = {
  instruments: {
    loading: false,
    data: [],
    error: false,
    filterModel: {},
    showCancel: false,
    selectedNodes: [],
    sortModel: [],
  },
};

const InstrumentsReducer = (state = initialState, action: any) => {
  const { payload } = action;

  switch (action.type) {
    case actionTypes.FETCH_INSTRUMENTS_START: {
      return R.mergeDeepRight(state, { instruments: { loading: true } });
    }
    case actionTypes.FETCH_INSTRUMENTS_SUCCESS: {
      const shouldUpdateState = payload.shouldUpdateState;

      if (!shouldUpdateState) {
        return R.mergeDeepRight(state, {
          instruments: {
            loading: false,
          },
        });
      }

      return R.mergeDeepRight(state, {
        instruments: {
          data: R.propOr([], 'rows', payload),
          lastRow: R.propOr(0, 'lastRow', payload),

          loading: false,
        },
      });
    }
    case actionTypes.FETCH_INSTRUMENTS_ERROR: {
      return R.mergeDeepRight(state, { instruments: { error: payload } });
    }

    case actionTypes.SET_INSTRUMENTS_TABLE_FILTER: {
      return R.mergeRight(state, {
        instruments: { ...state.instruments, filterModel: payload },
      });
    }
    case actionTypes.SET_INSTRUMENTS_TABLE_SORT: {
      return R.mergeRight(state, {
        instruments: { ...state.instruments, sortModel: payload },
      });
    }
    case actionTypes.SET_INSTRUMENTS_SHOW_CANCEL: {
      return R.mergeDeepRight(state, {
        instruments: { showCancel: !state.instruments.showCancel },
      });
    }

    case actionTypes.SET_INSTRUMENTS_SELECTED_NODES: {
      return R.mergeDeepRight(state, {
        instruments: { selectedNodes: payload },
      });
    }
    default:
      return state;
  }
};

export default InstrumentsReducer;
