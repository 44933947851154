const LogoFullIconVertical = () => (
  <svg width="98px" height="83px" viewBox="0 0 98 83" version="1.1">
    <title>F42FA0AC-5515-4077-ADE3-CBA8B6A82F01</title>
    <defs>
      <linearGradient x1="0%" y1="50%" x2="100%" y2="50%" id="linearGradient-1">
        <stop stopColor="#FFCC00" offset="0%"></stop>
        <stop stopColor="#FFC902" offset="17%"></stop>
        <stop stopColor="#FFC108" offset="28%"></stop>
        <stop stopColor="#FFB313" offset="36%"></stop>
        <stop stopColor="#FF9F22" offset="44%"></stop>
        <stop stopColor="#FF8733" offset="50%"></stop>
        <stop stopColor="#F67A33" offset="53%"></stop>
        <stop stopColor="#E36033" offset="59%"></stop>
        <stop stopColor="#D44C33" offset="66%"></stop>
        <stop stopColor="#CA3E33" offset="74%"></stop>
        <stop stopColor="#C43633" offset="84%"></stop>
        <stop stopColor="#C23333" offset="100%"></stop>
      </linearGradient>
    </defs>
    <g
      id="Authentication"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Login"
        transform="translate(-671.000000, -255.000000)"
        fillRule="nonzero"
      >
        <g id="Group" transform="translate(570.000000, 255.000000)">
          <g id="logo-full" transform="translate(101.000000, 0.000000)">
            <g
              id="Global/Logo-Text"
              transform="translate(0.194790, 58.543779)"
              fill="#000000"
            >
              <path
                d="M8.70822203,15.1523753 L5.76114982,15.1523753 L5.76114982,22.7096774 L0,22.7096774 L0,0.405529954 L11.1861595,0.405529954 C16.1420345,0.405529954 18.9553813,3.68252561 18.9553813,7.82857089 C18.9553813,11.7075531 16.5775552,13.7813054 14.5015244,14.5168234 L19.0554926,22.7096774 L12.4583769,22.7096774 L8.70822203,15.1523753 Z M10.3158488,5.25425766 L5.76114982,5.25425766 L5.76114982,10.2700822 L10.3151181,10.2700822 C11.8226336,10.2700822 13.091928,9.30033669 13.091928,7.76216995 C13.091928,6.22400321 11.8226336,5.25425766 10.3151181,5.25425766 L10.3158488,5.25425766 Z"
                id="Shape"
              ></path>
              <path
                d="M21.8197332,18.2145887 C31.4920995,11.2036849 33.3015136,9.66067115 33.3015136,7.44643186 C33.3015136,5.90341816 31.862456,5.09824023 30.1217076,5.09824023 C27.7447059,5.09824023 25.8702785,6.03737049 24.2632091,7.54744509 L21.0826727,3.72358192 C23.4596744,1.10675366 27.0083759,0 30.1217076,0 C35.3812075,0 39.160012,2.95134309 39.160012,7.44643186 C39.160012,10.868438 36.716536,13.7861101 30.7243586,17.6780474 L39.3272933,17.6780474 L39.3272933,22.7096774 L21.8197332,22.7096774 L21.8197332,18.2145887 Z"
                id="Path"
              ></path>
              <path
                d="M42.5707814,0.405529954 L51.9575188,0.405529954 C58.9968404,0.405529954 64.0588901,4.61870585 64.0588901,11.5404562 C64.0588901,18.4622065 58.9968404,22.7096774 51.9889738,22.7096774 L42.5707814,22.7096774 L42.5707814,0.405529954 Z M51.9575188,17.6938528 C55.8791444,17.6938528 58.1592613,14.8480984 58.1592613,11.5404562 C58.1592613,8.09636367 56.0803097,5.42135453 51.9904368,5.42135453 L48.3365439,5.42135453 L48.3365439,17.6938528 L51.9575188,17.6938528 Z"
                id="Shape"
              ></path>
              <polygon
                id="Path"
                points="67.5050963 0.405529954 70.3431484 0.405529954 70.3431484 22.7096774 67.5050963 22.7096774"
              ></polygon>
              <path
                d="M85.7497169,0 C92.3854608,0 96.8992072,4.95785378 96.8992072,11.5576037 C96.8992072,18.1573536 92.3854608,23.1152074 85.7497169,23.1152074 C79.0801621,23.1152074 74.6002265,18.1573536 74.6002265,11.5576037 C74.6002265,4.95785378 79.0801621,0 85.7497169,0 Z M85.7497169,2.47892689 C80.6972021,2.47892689 77.4976681,6.33146145 77.4976681,11.5576037 C77.4976681,16.7479254 80.6972021,20.6362805 85.7497169,20.6362805 C90.7684208,20.6362805 94.0025007,16.7501185 94.0025007,11.5576037 C94.0025007,6.33146145 90.7684208,2.47892689 85.7497169,2.47892689 L85.7497169,2.47892689 Z"
                id="Shape"
              ></path>
            </g>
            <g id="logo-mark" transform="translate(27.000000, 0.000000)">
              <path
                d="M33.6511891,44 C22.7021074,44 25.7165536,0 21.2853907,0 C16.8542278,0 19.868674,44 8.9195923,44 L33.6511891,44 Z"
                id="Path"
                fill="url(#linearGradient-1)"
              ></path>
              <path
                d="M33.6511891,44 C22.7021074,44 25.7165536,0 21.2853907,0 C16.8542278,0 19.868674,44 8.9195923,44 L33.6511891,44 Z"
                id="Path"
                fill="url(#linearGradient-1)"
              ></path>
              <path
                d="M41.7716632,35.0023205 C37.1875011,35.0023205 27.8190369,30.7461007 18.0774177,23.9314614 C9.20233917,17.7225515 2.43129797,11.0785712 0.110992058,6.69124424 C-0.891174824,13.1264787 4.96200761,22.4966077 14.8825067,29.4364946 C25.3154525,36.7345456 36.7293049,38.884995 42.1653454,34.989869 C42.0377836,34.9971935 41.9072893,35.0023205 41.7716632,35.0023205 Z"
                id="Path"
                fill="#C23333"
              ></path>
              <path
                d="M16.9143244,14.700975 C17.0981656,12.6621139 17.2659571,10.8443605 17.4337486,9.22801222 C11.0642367,5.55528936 5.30461055,3.24423963 2.15888438,3.24423963 C1.27907321,3.24423963 0.828954234,3.43980683 0.699827719,3.62296865 C0.422606953,4.01848143 0.547356298,6.01501823 4.40145447,10.4379019 C7.3195678,13.7880848 11.4880927,17.5075102 16.3080864,21.0875576 C16.5320516,18.9268319 16.7268356,16.7821603 16.9143244,14.700975 Z"
                id="Path"
                fill="#DD3A3A"
              ></path>
              <path
                d="M39.3867985,26.4075371 C36.1247373,22.6332535 31.2998027,18.3860812 25.7451869,14.3963134 C25.7502895,14.4500006 25.7546632,14.5014814 25.7597659,14.5559041 C26.1118498,18.5000746 26.5113156,22.9715564 27.08573,27.0782594 L27.2315204,28.1262629 C33.2497498,31.5379741 38.6286882,33.6589862 41.6297844,33.6589862 C42.5081718,33.6589862 42.9586643,33.461888 43.0876888,33.2772923 C43.3639617,32.8779477 43.2393109,30.8657809 39.3867985,26.4075371 Z"
                id="Path"
                fill="#DD3A3A"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default LogoFullIconVertical;
