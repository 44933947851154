import * as actionTypes from 'modules/GlobalActionsTypes';

export const STATE_KEY = 'feedbackPage';

export type FeedbackDataType = {
  data: string;
  loading: boolean;
  error: string;
};

const initialState = {
  loading: false,
  data: '',
  error: false,
};

const ExperimentsReducer = (state = initialState, action: any) => {
  const { payload } = action;

  switch (action.type) {
    case actionTypes.FETCH_FEEDBACK_DATA_START: {
      return {
        ...state,
        loading: true,
      };
    }
    case actionTypes.FETCH_FEEDBACK_DATA_SUCCESS: {
      return {
        ...state,
        loading: false,
        data: payload,
      };
    }
    case actionTypes.FETCH_FEEDBACK_DATA_ERROR: {
      return {
        ...state,
        loading: false,
        error: payload,
      };
    }
    case actionTypes.CLEAR_FEEDBACK_DATA: {
      return initialState;
    }
    default:
      return state;
  }
};

export default ExperimentsReducer;
