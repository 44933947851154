import format from 'date-fns/format';
import { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { AccessTime, Clear, Today } from '@mui/icons-material';
import { IconButton, TextField, TextFieldProps } from '@mui/material';
import {
  Control,
  Controller,
  FieldError,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form';

import { DatePickerProps } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import DatePickerToolbar from 'components/DatePickerToolbar';
import TransitionComponent from 'components/TransitionComponent';
import { DataPickerStatus } from 'modules/GlobalReducer';

interface DatePickerFieldProps extends Partial<DatePickerProps<any, any>> {
  name: string;
  control: Control<any>;
  error: FieldError | undefined;
  setValue: UseFormSetValue<any>;
  watch: UseFormWatch<any>;
  label?: string;
  placeholder?: string;
  required?: boolean;
}

const DatePickerField: React.FC<DatePickerFieldProps> = ({
  error,
  name,
  control,
  required = false,
  setValue,
  placeholder,
  watch,
  label = 'Started Date',
  ...props
}) => {
  const date = watch(name);

  const [selectedDate, setSelectedDate] = useState<null | Date>(date);
  const [openStatus, setIsOpenStatus] = useState<DataPickerStatus>(
    DataPickerStatus.closed,
  );
  const prevValue = useRef(selectedDate);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const clearDateInput = () => {
    prevValue.current = null;

    if (inputRef.current) {
      inputRef.current.focus();
      inputRef.current.value = '';
    }

    return setValue(name, null);
  };

  const setDataPickerStatus = useCallback(
    (status: DataPickerStatus) => {
      setIsOpenStatus(status);
      if (status === DataPickerStatus.canceled) {
        return setSelectedDate(prevValue.current);
      }
      if (status === DataPickerStatus.ok) {
        prevValue.current = selectedDate;
        return setValue(name, selectedDate, {
          shouldDirty: true,
          shouldValidate: true,
        });
      }
      if (status === DataPickerStatus.clear) {
        clearDateInput();
      }
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedDate, setValue],
  );

  useEffect(() => {
    if (openStatus === DataPickerStatus.closed && selectedDate) {
      setSelectedDate(date);
    }
  }, [openStatus, selectedDate, date]);

  return (
    <Wrapper>
      <IconWrap>
        <AccessTime fontSize="medium" />
      </IconWrap>

      <Controller
        control={control}
        name={name}
        rules={{
          validate: (value) => {
            const valueToString = value?.toString();
            if (required && !value) {
              return 'Required';
            } else if (valueToString === 'Invalid Date') {
              return 'Invalid Date';
            }
          },
        }}
        render={({ field: { onChange } }) => (
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
              showToolbar
              closeOnSelect={false}
              inputFormat="yyyy/MM/dd"
              mask="____/__/__"
              minDate={new Date('1900-01-01')}
              value={selectedDate}
              open={openStatus === DataPickerStatus.open}
              onChange={(date: Date, keyboardInputValue?: string) => {
                keyboardInputValue
                  ? onChange(
                      keyboardInputValue.length < 10 ||
                        isNaN(Date.parse(keyboardInputValue ?? ''))
                        ? 'Invalid Date'
                        : keyboardInputValue,
                    )
                  : setSelectedDate(date);
              }}
              onClose={() => setIsOpenStatus(DataPickerStatus.closed)}
              TransitionComponent={TransitionComponent}
              ToolbarComponent={(props: any) => (
                <DatePickerToolbar
                  {...props}
                  date={selectedDate}
                  setDataPickerStatus={setDataPickerStatus}
                />
              )}
              renderInput={(params: TextFieldProps) => (
                <TextField
                  {...params}
                  inputRef={(ref) => (inputRef.current = ref)}
                  variant="filled"
                  fullWidth
                  inputProps={{
                    ...params.inputProps,
                    placeholder: placeholder ?? '____/__/__',
                    value:
                      openStatus === DataPickerStatus.open || date === null
                        ? inputRef.current?.value
                        : date && date !== 'Invalid Date' // prevent keyboard input
                        ? format(new Date(date), 'yyyy/MM/dd')
                        : params.inputProps?.value,
                  }}
                  label={label}
                  margin="dense"
                  helperText={error && error.message}
                  InputProps={{
                    startAdornment: (
                      <IconButton
                        style={{ position: 'absolute', right: 35 }}
                        edge="end"
                        size="small"
                        tabIndex={-1}
                        onClick={clearDateInput}
                      >
                        {date && <Clear />}
                      </IconButton>
                    ),
                    endAdornment: (
                      <IconButton
                        edge="end"
                        size="small"
                        style={{ position: 'absolute', right: 5 }}
                        onClick={() => {
                          setIsOpenStatus(DataPickerStatus.open);
                        }}
                      >
                        <Today />
                      </IconButton>
                    ),
                  }}
                />
              )}
              {...props}
            />
          </LocalizationProvider>
        )}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

const IconWrap = styled.div`
  display: flex;
  width: 43px;
  padding-top: 7px;
`;

export default DatePickerField;
