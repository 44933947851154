import {
  BubbleDataPoint,
  Chart,
  ChartOptions,
  ChartTypeRegistry,
  Point,
  TooltipItem,
} from 'chart.js';
import { format } from 'date-fns';
import colors from 'theme/patterns/colors';
import { ModelName } from '../../../../utils/enum';
import { ChartRawDataInfoType } from '../../VisualizeReducer';
import { CenterLineOptions } from './ScatterSettings';
import { ScatterGroupsEnum } from './constants/scatterChartConstants';
import {
  LegendValueEnum,
  ScatterChartAxesBasesType,
} from './models/scatterChartModels';
import { ScatterGroupType } from './models/scatterChartResponseModel';

type ChartSettings = {
  xMinAxis: Date;
  xMaxAxis: Date;
  yMinAxis: number;
  yMaxAxis: number;
  highlightOutliers: boolean;
  yAxis: {
    name: string;
    value: string;
  };
  xAxis: {
    name: string;
    value: string;
  };
  labelBy: [
    {
      name: string;
      value: string;
    },
  ];
};

const getTimestamp = (str: string | Date) => new Date(str).getTime();
export const getFixedNumber = (num: number) =>
  Math.round(num * 100 + Number.EPSILON) / 100;

export const getChartDatasetGroups = (data: ScatterGroupType) => {
  if (!data) {
    return {
      defaultGroup: [],
      zone9Group: [],
      beyondLimitsGroup: [],
    };
  }

  return data.dots.reduce<{
    defaultGroup: ScatterGroupType['dots'];
    zone9Group: ScatterGroupType['dots'];
    beyondLimitsGroup: ScatterGroupType['dots'];
  }>(
    (acc, cur) => {
      const isDefault = cur.groups.length === 0;
      const isZone9 = cur.groups.includes(ScatterGroupsEnum.Zone9);

      if (isZone9) {
        return {
          ...acc,
          zone9Group: [...acc.zone9Group, cur],
        };
      }

      if (isDefault) {
        return {
          ...acc,
          defaultGroup: [...acc.defaultGroup, cur],
        };
      }

      return {
        ...acc,
        beyondLimitsGroup: [...acc.beyondLimitsGroup, cur],
      };
    },
    {
      defaultGroup: [],
      zone9Group: [],
      beyondLimitsGroup: [],
    },
  );
};

export const getChartDataset = ({
  data,
  showControlLimits,
  highlightTrends,
  settings,
  centerLine,
}: {
  data: ScatterGroupType;
  showControlLimits: boolean;
  highlightTrends: boolean;
  settings: ChartSettings;
  centerLine: CenterLineOptions;
  modelName: ModelName;
}) => {
  const dataChartGroups = getChartDatasetGroups(data);

  const isHighlightOutliers = settings.highlightOutliers;

  const controlLimitLCL = {
    label: LegendValueEnum.LCL,
    data: [
      {
        x: getTimestamp(settings.xMinAxis ?? data.domain.x[0]),
        y: data.lines.LCL.y,
      },
      {
        x: getTimestamp(settings.xMaxAxis ?? data.domain.x[1]),
        y: data.lines.LCL.y,
      },
    ],
    showLine: true,
    pointRadius: 0,
    borderWidth: 1,
    borderColor: colors.red,
  };
  const controlLimitUCL = {
    label: LegendValueEnum.UCL,
    data: [
      {
        x: getTimestamp(settings.xMinAxis ?? data.domain.x[0]),
        y: data.lines.UCL.y,
      },
      {
        x: getTimestamp(settings.xMaxAxis ?? data.domain.x[1]),
        y: data.lines.UCL.y,
      },
    ],
    showLine: true,
    pointRadius: 0,
    borderWidth: 1,
    borderColor: colors.red,
  };

  const trend = {
    label:
      centerLine === CenterLineOptions.Mean
        ? LegendValueEnum.MEAN
        : LegendValueEnum.Median,
    data: [
      {
        x: getTimestamp(settings.xMinAxis ?? data.domain.x[0]),
        y: data.lines.center.y,
      },
      {
        x: getTimestamp(settings.xMaxAxis ?? data.domain.x[1]),
        y: data.lines.center.y,
      },
    ],
    showLine: true,
    pointRadius: 0,
    borderWidth: 1,
    borderColor: colors.green,
  };

  let datasets = [
    {
      label: settings.yAxis.name,
      data: dataChartGroups.defaultGroup.map((x) => ({
        x: getTimestamp(x.x),
        y: x.y,
        id: x.meta['id'],
        groups: x.groups,
      })),
      backgroundColor: '#455a64',
    },
    {
      label: ScatterGroupsEnum.BeyondLimits,
      data: dataChartGroups.beyondLimitsGroup.map((x) => ({
        x: getTimestamp(x.x),
        y: x.y,
        groups: x.groups,
        id: x.meta['id'],
      })),
      backgroundColor: isHighlightOutliers ? colors.red : '#455a64',
    },
    {
      label: ScatterGroupsEnum.Zone9,
      data: dataChartGroups.zone9Group.map((x) => ({
        x: getTimestamp(x.x),
        y: x.y,
        groups: x.groups,
        id: x.meta['id'],
      })),
      backgroundColor: highlightTrends ? colors.orange : colors.black,
    },
    controlLimitUCL,
    trend,
    controlLimitLCL,
  ];

  if (centerLine === CenterLineOptions.None) {
    datasets = datasets.filter(
      (d) =>
        !(
          d.label === LegendValueEnum.MEAN || d.label === LegendValueEnum.Median
        ),
    );
  }

  if (!showControlLimits) {
    datasets = datasets.filter(
      (d) =>
        !(d.label === LegendValueEnum.LCL || d.label === LegendValueEnum.UCL),
    );
  }

  return { datasets };
};

const getOrCreateLegendList = (id: string, showTitle: boolean) => {
  const legendContainer = document.getElementById(id)!;

  let listContainer = legendContainer.querySelector('ul');

  if (!listContainer) {
    listContainer = document.createElement('ul');
    listContainer.style.display = 'flex';
    listContainer.style.flexDirection = 'column';
    listContainer.style.backgroundColor = colors.white;
    listContainer.style.marginTop = showTitle ? '60px' : '20px';
    listContainer.style.padding = '0';
    listContainer.style.zIndex = '10';

    legendContainer.appendChild(listContainer);
  } else {
    listContainer.style.marginTop = showTitle ? '60px' : '20px';
  }

  return listContainer;
};

export const htmlLegendPlugin = {
  id: 'htmlLegend',
  afterUpdate(
    chart: Chart<
      keyof ChartTypeRegistry,
      (number | Point | [number, number] | BubbleDataPoint | null)[],
      unknown
    >,
    args: any,
    options: any,
  ) {
    const ul = getOrCreateLegendList(options.containerID, options.showTitle);
    if (!options.showLegend) {
      return ul.remove();
    }

    // Remove old legend items
    while (ul.firstChild) {
      ul.firstChild.remove();
    }

    // Reuse the built-in legendItems generator
    const items =
      (chart?.options?.plugins?.legend?.labels?.generateLabels &&
        chart.options.plugins.legend.labels.generateLabels(chart)) ??
      [];

    items.forEach((item: any) => {
      const li = document.createElement('li');
      li.style.alignItems = 'center';
      li.style.cursor = 'pointer';
      li.style.display = 'flex';
      li.style.flexDirection = 'row';
      li.style.marginLeft = '10px';

      // TODOHIGH: temporary until backend change
      if (item.text === 'Zone9') {
        item.text = 'Trend';
      }

      if (item.text === 'Beyond limits') {
        item.text = 'Outlier';
      }

      li.onclick = () => {
        const { type } = chart.config;
        if (type === 'pie' || type === 'doughnut') {
          // Pie and doughnut charts only have a single dataset and visibility is per item
          chart.toggleDataVisibility(item.index);
        } else {
          chart.setDatasetVisibility(
            item.datasetIndex,
            !chart.isDatasetVisible(item.datasetIndex),
          );
        }
        chart.update();
      };

      const isLineBox = ['UCL', 'LCL', 'Mean', 'Median'].includes(item.text);

      const boxSpan = document.createElement('span');

      if (isLineBox) {
        // Color box
        boxSpan.style.background =
          item.text === LegendValueEnum.MEAN ||
          item.text === LegendValueEnum.Median
            ? colors.green
            : colors.red;
        boxSpan.style.display = 'inline-block';
        boxSpan.style.height = '2px';
        boxSpan.style.marginRight = '10px';
        boxSpan.style.width = '26px';
      } else {
        if (item.text === 'Trend') {
          // Color box
          boxSpan.style.display = 'inline-block';
          boxSpan.style.height = '0px';
          boxSpan.style.marginRight = '18px';
          boxSpan.style.marginLeft = '6px';
          boxSpan.style.width = '0px';
          boxSpan.style.border = '6px solid transparent';
          boxSpan.style.borderTop = '0px';
          boxSpan.style.borderBottom = `10px solid ${item.fillStyle}`;
        } else if (item.text === 'Outlier') {
          // Color box
          boxSpan.style.background = item.fillStyle;
          boxSpan.style.display = 'inline-block';
          boxSpan.style.height = '8px';
          boxSpan.style.marginRight = '18px';
          boxSpan.style.marginLeft = '8px';
          boxSpan.style.width = '8px';
          boxSpan.style.rotate = '135deg';
        } else {
          // Color box
          boxSpan.style.background = item.fillStyle;
          boxSpan.style.display = 'inline-block';
          boxSpan.style.height = '8px';
          boxSpan.style.marginRight = '18px';
          boxSpan.style.marginLeft = '8px';
          boxSpan.style.width = '8px';
          boxSpan.style.borderRadius = '50%';
        }
      }

      // Text
      const textContainer = document.createElement('p');
      textContainer.style.color = '#595959';
      textContainer.style.margin = '0';
      textContainer.style.padding = '6px 0';
      textContainer.style.whiteSpace = 'nowrap';
      textContainer.style.textDecoration = item.hidden ? 'line-through' : '';
      textContainer.style.fontSize = '14px';
      textContainer.style.lineHeight = '17px';
      textContainer.style.textTransform = 'capitalize';

      const text = document.createTextNode(item.text);
      textContainer.appendChild(text);

      li.appendChild(boxSpan);
      li.appendChild(textContainer);
      ul.appendChild(li);
    });
  },
};

export const getScatterChartOptions = ({
  data,
  chartTitle,
  showTitle,
  isDisplaySplit,
  showLegend,
  chartId,
  settings,
  chartRawDataInfo,
}: {
  data: ScatterGroupType;
  showTitle: boolean;
  chartTitle: string;
  isDisplaySplit: boolean;
  showLegend: boolean;
  chartId: string;
  settings: ChartSettings;
  chartRawDataInfo: ChartRawDataInfoType;
}) => {
  const minXAxis = getTimestamp(settings.xMinAxis);
  const maxXAxis = getTimestamp(settings.xMaxAxis);

  const deltaX = (maxXAxis - minXAxis) / 90;

  const minYAxis = getFixedNumber(settings.yMinAxis);
  const maxYAxis = getFixedNumber(settings.yMaxAxis);

  const yAxisTitle = settings.yAxis.name;
  const xAxisTitle = settings.xAxis.name;

  const options: ChartOptions = {
    scales: {
      y: {
        min: minYAxis === maxYAxis ? minYAxis - minYAxis * 0.1 : minYAxis,
        max: maxYAxis === minXAxis ? maxYAxis + maxYAxis * 0.1 : maxYAxis,
        grace: '10%',
        ticks: {
          count: data.y_tick_count ?? 5,
          padding: 10,
        },
        grid: {
          drawTicks: false,
          color: '#e1e1e1',
        },
        title: {
          display: true,
          text: yAxisTitle,
          color: 'black',
          font: {
            weight: '600',
            size: 16,
          },
        },
      },
      x: {
        grid: {
          drawTicks: false,
          color: '#e1e1e1',
        },
        ticks: {
          padding: 10,
          minRotation: 45,
          callback(tickValue: string | number) {
            return format(new Date(tickValue), 'yyyy/MM/dd');
          },
          labelOffset: 10,
          count: data.x_tick_count,
        },
        min: minXAxis - deltaX,
        max: maxXAxis + deltaX,
        title: {
          display: true,
          text: xAxisTitle,
          color: 'black',
          font: {
            weight: '600',
            size: 16,
          },
        },
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    resizeDelay: 300,
    animation: false,
    elements: {
      point: {
        radius(ctx) {
          const groups = (ctx.raw as any)?.groups;

          const { isTrendGroup, isOutlerGroup } = getPointGroup(groups);

          if (!groups || groups.length === 0) {
            return 2;
          }

          if (isTrendGroup) {
            return 3.5;
          }

          if (isOutlerGroup) {
            return 3.5;
          }
        },
        hoverRadius: 2,
        pointStyle(ctx) {
          const groups = (ctx.raw as any)?.groups;

          const { isTrendGroup, isOutlerGroup } = getPointGroup(groups);

          if (!groups || groups.length === 0) {
            return 'circle';
          }

          if (isTrendGroup) {
            return 'triangle';
          }

          if (isOutlerGroup) {
            return 'rectRot';
          }
        },
      },
    },
    layout: {
      padding: {
        left: isDisplaySplit ? 20 : 60,
        right: 20,
        bottom: showTitle ? 60 : 20,
        top: showTitle ? 0 : 30,
      },
    },
    plugins: {
      ...{
        htmlLegend: {
          containerID: chartId,
          showTitle,
          showLegend,
        } as any,
      },
      legend: {
        display: false,
      },
      title: {
        display: false,
        text: chartTitle,
        color: 'black',
        font: {
          size: 16,
          weight: '600',
        },
        align: 'center',
      },
      tooltip: {
        position: 'nearest',
        mode: 'point',
        enabled: Boolean(settings.labelBy.length),
        backgroundColor: colors.white,
        bodyColor: colors.black,
        padding: {
          left: 8,
          right: 8,
          top: 8,
          bottom: 8,
        },
        boxPadding: 4,
        borderColor: colors.grey,
        borderWidth: 1,
        callbacks: {
          label: function (context: TooltipItem<keyof ChartTypeRegistry>) {
            const raw = context.raw as { x: number; y: number; id: number };
            const rowInfo = chartRawDataInfo.rawData.find(
              (dot) => dot['db_id'] === raw.id,
            );

            if (rowInfo) {
              return settings.labelBy.reduce((prev, curr, i) => {
                if (i === 0) {
                  return `${rowInfo[curr.name] ?? null} `;
                }
                return `${prev} | ${rowInfo[curr.name] ?? null}`;
              }, '');
            } else {
              return 'Loading label data.';
            }
          },
        },
      },
    },
  };

  return options;
};

export const getScatterColorGroups = (chartData?: ScatterGroupType) => {
  const colorByGroups: ScatterGroupsEnum[] = [ScatterGroupsEnum.Default];

  if (chartData) {
    chartData.dots.forEach((d) =>
      d.groups.forEach(
        (g) =>
          !colorByGroups.includes(g as ScatterGroupsEnum) &&
          colorByGroups.push(g as ScatterGroupsEnum),
      ),
    );
  }

  return colorByGroups.map((g) => ({ name: g, label: g }));
};

export const getScatterChartAxesBases = (
  modelDefinitions: [[string, string]],
): ScatterChartAxesBasesType => {
  const xAxisTypes = ['datetime.datetime'];
  const yAxisTypes = ['int', 'float'];

  const axes = modelDefinitions.reduce(
    (acc, [name, type]) => {
      const isXAxisType = xAxisTypes.includes(type);
      const isYAxisType = yAxisTypes.includes(type);

      if (isXAxisType) {
        const isAldreadyIncluded = acc.xAxis.some((x) => x.name === name);

        if (isAldreadyIncluded) {
          return acc;
        } else {
          return {
            ...acc,
            xAxis: [
              ...acc.xAxis,
              {
                name,
                label: name,
              },
            ],
          };
        }
      }

      if (isYAxisType) {
        const isAldreadyIncluded = acc.yAxis.some((x) => x.name === name);

        if (isAldreadyIncluded) {
          return acc;
        } else {
          return {
            ...acc,
            yAxis: [
              ...acc.yAxis,
              {
                name,
                label: name,
              },
            ],
          };
        }
      }

      return acc;
    },
    {
      xAxis: [{ label: 'datetime', name: 'datetime' }],
      yAxis: [{ label: 'value', name: 'value' }],
    },
  );

  return axes;
};

export const getPointGroup = (groups: string[]) => {
  const isTrendGroup = groups?.includes('Zone9');
  const isOutlerGroup = groups?.includes('Beyond limits');

  return {
    isTrendGroup,
    isOutlerGroup,
  };
};
