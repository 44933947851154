import { useCallback, useRef, useState, useEffect } from 'react';
import styled from 'styled-components';

import { Today } from '@mui/icons-material';
import {
  Control,
  Controller,
  FieldError,
  UseFormWatch,
  UseFormSetValue,
} from 'react-hook-form';
import { IconButton, TextField, TextFieldProps } from '@mui/material';

import TransitionComponent from 'components/TransitionComponent';
import DatePickerToolbar from 'components/DatePickerToolbar';
import { DataPickerStatus } from 'modules/GlobalReducer';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DateTimePickerProps, DateTimePicker } from '@mui/x-date-pickers';

interface DateTimePickerFieldProps
  extends Partial<DateTimePickerProps<any, any>> {
  name: string;
  control: Control<any>;
  error: FieldError | undefined;
  setValue: UseFormSetValue<any>;
  watch: UseFormWatch<any>;
  placeholder?: string;
  required?: boolean;
}

const DateTimePickerField: React.FC<DateTimePickerFieldProps> = ({
  error,
  name,
  control,
  required = false,
  setValue,
  placeholder,
  watch,
  label,
  ...props
}) => {
  const date = watch(name);

  const [selectedDate, setSelectedDate] = useState<null | Date>(date);
  const [openStatus, setIsOpenStatus] = useState<DataPickerStatus>(
    DataPickerStatus.closed,
  );
  const prevValue = useRef(selectedDate);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const clearDateInput = () => {
    prevValue.current = null;

    if (inputRef.current) {
      inputRef.current.focus();
      inputRef.current.value = '';
    }

    return setValue(name, null, { shouldDirty: true });
  };

  const setDataPickerStatus = useCallback(
    (status: DataPickerStatus) => {
      setIsOpenStatus(status);
      if (status === DataPickerStatus.canceled) {
        return setSelectedDate(prevValue.current);
      }
      if (status === DataPickerStatus.ok) {
        prevValue.current = selectedDate;
        return setValue(name, selectedDate, {
          shouldDirty: true,
          shouldValidate: true,
        });
      }
      if (status === DataPickerStatus.clear) {
        clearDateInput();
      }
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedDate, setValue],
  );

  useEffect(() => {
    if (openStatus === DataPickerStatus.closed && selectedDate) {
      setSelectedDate(date);
    }
  }, [openStatus, selectedDate, date]);

  return (
    <Wrapper>
      <Controller
        control={control}
        name={name}
        rules={{
          validate: (value) => {
            const valueToString = value?.toString();
            if (required && !value) {
              return 'Required';
            } else if (valueToString === 'Invalid Date') {
              return 'Invalid Date';
            }
          },
        }}
        render={({ field: { onChange } }) => (
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateTimePicker
              showToolbar
              closeOnSelect={false}
              inputFormat="yyyy/MM/dd hh:mm aaa"
              minDate={new Date('1900-01-01')}
              value={selectedDate}
              open={openStatus === DataPickerStatus.open}
              onChange={(date: Date, keyboardInputValue?: string) => {
                if (!date && !keyboardInputValue) {
                  return onChange('Invalid Date');
                }

                if (keyboardInputValue) {
                  const isInvalid =
                    keyboardInputValue.length < 16 ||
                    isNaN(Date.parse(keyboardInputValue ?? ''));

                  if (isInvalid) {
                    onChange('Invalid Date');
                  } else {
                    onChange(keyboardInputValue);
                    setSelectedDate(new Date(keyboardInputValue));
                  }

                  return;
                }

                setSelectedDate(date);
              }}
              onClose={() => setIsOpenStatus(DataPickerStatus.closed)}
              TransitionComponent={TransitionComponent}
              ToolbarComponent={(props: any) => (
                <DatePickerToolbar
                  {...props}
                  date={selectedDate}
                  setDataPickerStatus={setDataPickerStatus}
                  showClearButton={false}
                />
              )}
              renderInput={(params: TextFieldProps) => (
                <TextField
                  {...params}
                  inputRef={(ref) => (inputRef.current = ref)}
                  variant="filled"
                  fullWidth
                  inputProps={{
                    ...params.inputProps,
                    placeholder: placeholder ?? '____/__/__ __:__ am',
                  }}
                  label={label}
                  margin="dense"
                  helperText={error && error.message}
                  error={!!error}
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        edge="end"
                        size="small"
                        style={{ position: 'absolute', right: 5 }}
                        onClick={() => {
                          setIsOpenStatus(DataPickerStatus.open);
                        }}
                      >
                        <Today />
                      </IconButton>
                    ),
                  }}
                />
              )}
              {...props}
            />
          </LocalizationProvider>
        )}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  .MuiFormLabel-root {
    top: -2px;
  }

  .MuiInputBase-input {
    font-size: 14px;
    font-weight: 500;
    padding-top: 20px;
    padding-bottom: 6px;
  }
`;

export default DateTimePickerField;
