import * as R from 'ramda';

import * as actionTypes from 'modules/GlobalActionsTypes';

export const STATE_KEY = 'controlTypes';

const initialState = {
  controlTypes: {
    loading: false,
    data: [],
    error: false,
    filterModel: {},
    showCancel: false,
    selectedNodes: [],
    sortModel: [],
  },
};

const ControlTypesReducer = (state = initialState, action: any) => {
  const { payload } = action;

  switch (action.type) {
    case actionTypes.FETCH_CONTROL_TYPES_START: {
      return R.mergeDeepRight(state, { controlTypes: { loading: true } });
    }
    case actionTypes.FETCH_CONTROL_TYPES_SUCCESS: {
      const shouldUpdateState = payload.shouldUpdateState;

      if (!shouldUpdateState) {
        return R.mergeDeepRight(state, {
          controlTypes: {
            loading: false,
          },
        });
      }

      return R.mergeDeepRight(state, {
        controlTypes: {
          data: R.propOr([], 'rows', payload),
          lastRow: R.propOr(0, 'lastRow', payload),
          loading: false,
        },
      });
    }
    case actionTypes.FETCH_CONTROL_TYPES_ERROR: {
      return R.mergeDeepRight(state, { controlTypes: { error: payload } });
    }

    case actionTypes.SET_CONTROL_TYPES_TABLE_FILTER: {
      return R.mergeRight(state, {
        controlTypes: { ...state.controlTypes, filterModel: payload },
      });
    }
    case actionTypes.SET_CONTROL_TYPES_TABLE_SORT: {
      return R.mergeRight(state, {
        controlTypes: { ...state.controlTypes, sortModel: payload },
      });
    }
    case actionTypes.SET_CONTROL_TYPES_SHOW_CANCEL: {
      return R.mergeDeepRight(state, {
        controlTypes: { showCancel: !state.controlTypes.showCancel },
      });
    }
    case actionTypes.SET_CONTROL_TYPES_SELECTED_NODES: {
      return R.mergeDeepRight(state, {
        controlTypes: { selectedNodes: payload },
      });
    }

    default:
      return state;
  }
};

export default ControlTypesReducer;
