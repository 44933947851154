import * as R from 'ramda';
import { API_ENDPOINTS } from 'utils/enum';

const apiUrl = R.defaultTo(
  'https://integration.api.r2d.io',
  process.env.REACT_APP_API_URL,
);

const AppConfig = {
  apiUrl,
  uploadUrl: `${apiUrl}/${API_ENDPOINTS.upload.uploadFile}`,
  agGridLicenseKey: R.defaultTo(
    null,
    `Using_this_AG_Grid_Enterprise_key_( AG-040934 )_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_( legal@ag-grid.com )___For_help_with_changing_this_key_please_contact_( info@ag-grid.com )___( R2DIO Inc )_is_granted_a_( Single Application )_Developer_License_for_the_application_( R2DIO )_only_for_( 1 )_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_( R2DIO )_need_to_be_licensed___( R2DIO )_has_been_granted_a_Deployment_License_Add-on_for_( 1 )_Production_Environment___This_key_works_with_AG_Grid_Enterprise_versions_released_before_( 14 June 2024 )____[v2]_MTcxODMxOTYwMDAwMA==48554af84339fdd32066ef5a89df81cc`,
  ),
  supportUrl: 'https://support.r2dio.com',
  appEnv: R.defaultTo('staging', process.env.REACT_APP_ENV),
};

export default AppConfig;
