import * as R from 'ramda';

import * as actionTypes from 'modules/GlobalActionsTypes';

export const STATE_KEY = 'users';

const initialState = {
  users: {
    loading: false,
    data: [],
    error: false,
    filterModel: {},
    showCancel: false,
    selectedNodes: [],
    sortModel: [],
  },
};

const UsersReducer = (state = initialState, action: any) => {
  const { payload } = action;

  switch (action.type) {
    case actionTypes.FETCH_USERS_START: {
      return R.mergeDeepRight(state, { users: { loading: true } });
    }
    case actionTypes.FETCH_USERS_SUCCESS: {
      const shouldUpdateState = payload.shouldUpdateState;

      if (!shouldUpdateState) {
        return R.mergeDeepRight(state, {
          users: {
            loading: false,
          },
        });
      }

      return R.mergeDeepRight(state, {
        users: {
          data: R.propOr([], 'rows', payload),
          lastRow: R.propOr(0, 'lastRow', payload),

          loading: false,
        },
      });
    }
    case actionTypes.FETCH_USERS_ERROR: {
      return R.mergeDeepRight(state, { users: { error: payload } });
    }

    case actionTypes.SET_USERS_TABLE_FILTER: {
      return R.mergeRight(state, {
        users: { ...state.users, filterModel: payload },
      });
    }
    case actionTypes.SET_USERS_TABLE_SORT: {
      return R.mergeRight(state, {
        users: { ...state.users, sortModel: payload },
      });
    }
    case actionTypes.SET_USERS_SHOW_CANCEL: {
      return R.mergeDeepRight(state, {
        users: { showCancel: !state.users.showCancel },
      });
    }

    case actionTypes.SET_USERS_SELECTED_NODES: {
      return R.mergeDeepRight(state, {
        users: { selectedNodes: payload },
      });
    }
    default:
      return state;
  }
};

export default UsersReducer;
