import { ModelName } from '../../utils/enum';
import {
  HistogramChartAxesBasesType,
  HistogramChartTypeEnum,
} from './VisualizeComponents/HistogramChart/models/histogramChartModels';
import { getHistogramChartTypeByColumnName } from './VisualizeComponents/HistogramChart/utils/utils';
import {
  CenterLineOptions,
  SizingOptions,
} from './VisualizeComponents/ScatterChart/ScatterSettings';
import { ScatterChartAxesBasesType } from './VisualizeComponents/ScatterChart/models/scatterChartModels';
import { ChartTypeEnum } from './VisualizeReducer';

export const getChartTitle = (
  chartsTitles: string[],
  chartsCount: number,
  type: ChartTypeEnum,
): string => {
  const isScatterChart = type === ChartTypeEnum.Scatter;
  const isHistogramChart = type === ChartTypeEnum.Histogram;
  const mainTitle = isScatterChart
    ? 'Run/Control Chart'
    : isHistogramChart
    ? 'Histogram Chart'
    : 'DOE Chart';
  const initialNumber = chartsCount === 0 ? 1 : chartsCount;
  const chartTitle = `${mainTitle} ${initialNumber}`;
  const isNameExist = chartsTitles.includes(chartTitle);

  if (isNameExist) {
    return getChartTitle(chartsTitles, initialNumber + 1, type);
  }

  return chartTitle;
};

const xAxisChoices = [
  {
    name: 'datetime',
    label: 'datetime',
  },
  {
    name: 'value',
    label: 'value',
  },
];

const yAxisChoices = [
  {
    name: 'value',
    label: 'value',
  },
];

const groupByChoices = [
  {
    name: 'pro_type',
    label: 'pro_type',
  },
];

const colorByChoices = [
  {
    name: 'Measurement',
    label: 'Measurement',
  },
];

const labelByChoices = [
  {
    name: 'db_id',
    label: 'db_id',
  },
];

const histogramColorByChoices = [
  {
    name: 'pro_type',
    label: 'pro_type',
  },
];

export const availableDOEAxisChoices = [
  {
    name: 'pro_name',
    label: 'pro_name',
  },
  {
    name: 'mat_name',
    label: 'mat_name',
  },
  {
    name: 'pro_operator',
    label: 'pro_operator',
  },

  {
    name: 'pro_type',
    label: 'pro_type',
  },
  {
    name: 'mat_type',
    label: 'mat_type',
  },
  {
    name: 'type',
    label: 'type',
  },
  {
    name: 'is_input',
    label: 'is_input',
  },
  {
    name: 'datetime',
    label: 'datetime',
  },
  {
    name: 'x',
    label: 'x',
  },
  {
    name: 'y',
    label: 'y',
  },
  {
    name: 'z',
    label: 'z',
  },

  {
    name: 'file_name',
    label: 'file_name',
  },
  {
    name: 'current_file_name',
    label: 'current_file_name',
  },
  {
    name: 'pro_code',
    label: 'pro_code',
  },
  {
    name: 'pro_start_datetime',
    label: 'pro_start_datetime',
  },
  {
    name: 'pro_end_datetime',
    label: 'pro_end_datetime',
  },

  {
    name: 'pro_duration__min',
    label: 'pro_duration__min',
  },
  {
    name: 'pro_site',
    label: 'pro_site',
  },
  {
    name: 'pro_experiment',
    label: 'pro_experiment',
  },
  {
    name: 'pro_team',
    label: 'pro_team',
  },
  {
    name: 'pro_file_name',
    label: 'pro_file_name',
  },
  {
    name: 'pro_description',
    label: 'pro_description',
  },
  {
    name: 'pro_version',
    label: 'pro_version',
  },
  {
    name: 'pro_instrument',
    label: 'pro_instrument',
  },
  {
    name: 'mat_maker',
    label: 'mat_maker',
  },
  {
    name: 'mat_made_datetime',
    label: 'mat_made_datetime',
  },
  {
    name: 'mat_site',
    label: 'mat_site',
  },
  {
    name: 'mat_file_name',
    label: 'mat_file_name',
  },
  {
    name: 'mat_description',
    label: 'mat_description',
  },
  {
    name: 'mat_control_type',
    label: 'mat_control_type',
  },
];
const InitialDOEXAxisChoices = [
  {
    name: 'pro_operator',
    label: 'pro_operator',
  },
];
const DOEColorByChoices = [
  {
    name: 'pro_type',
    label: 'pro_type',
  },
];

function addDays(date: any, days: any) {
  const result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}

export const getChartSettings = ({
  chartType,
  chartsCount,
  chartsTitles,
  modelName,
  modelDefinitions,
}: {
  chartType: ChartTypeEnum;
  chartsTitles: string[];
  chartsCount: number;
  axesBases:
    | ScatterChartAxesBasesType
    | HistogramChartAxesBasesType
    | undefined;
  modelDefinitions: Array<Array<string>>;
  modelName: ModelName;
}) => {
  const allLabelByChoices = modelDefinitions.reduce(
    (acc, [name]) => {
      const isAldreadyIncluded = acc.some((x) => x.name === name);

      if (isAldreadyIncluded) {
        return acc;
      } else {
        return [
          ...acc,
          {
            name,
            label: name,
          },
        ];
      }
    },
    [
      {
        label: 'db_id',
        name: 'db_id',
      },
    ],
  );

  switch (chartType) {
    case ChartTypeEnum.Scatter:
      return {
        xAxis: xAxisChoices[0],
        yAxis: yAxisChoices[0],
        centerLine: CenterLineOptions.Mean,
        proportionalSpacing: true,
        showControlLimits: true,
        highlightOutliers: true,
        highlightTrends: true,
        groupBy: groupByChoices[0],
        colorBy: colorByChoices[0],
        labelBy: labelByChoices,
        labelByChoices: allLabelByChoices,
        showLegend: true,
        showLabels: true,
        xMinAxis: new Date(),
        xMaxAxis: addDays(new Date(), 3),
        yMinAxis: 90,
        yMaxAxis: 120,
        chartTitle: getChartTitle(
          chartsTitles,
          chartsCount,
          ChartTypeEnum.Scatter,
        ),
        sizingType: SizingOptions.Fit,
        chartWidth: 900,
        chartHeight: 528,
        showTitle: true,
        hasInitialized: false,
      };
    case ChartTypeEnum.Histogram: {
      const column = {
        name: getInitialAxesBasesForHistogram(modelName),
        label: getInitialAxesBasesForHistogram(modelName),
      };

      const histogramChartType = getHistogramChartTypeByColumnName(
        modelDefinitions,
        column.name,
      );

      return {
        column,
        showBoxAndWhiskers:
          histogramChartType === HistogramChartTypeEnum.CATEGORICAL ||
          column.name !== 'value'
            ? false
            : true,
        showMeanDiamonds:
          histogramChartType === HistogramChartTypeEnum.CATEGORICAL ||
          column.name !== 'value'
            ? false
            : true,
        colorBy: histogramColorByChoices[0],
        labelBy: labelByChoices,
        labelByChoices: allLabelByChoices,
        minAxis: 0,
        maxAxis: 0,
        binSize: 0,
        chartTitle: getChartTitle(
          chartsTitles,
          chartsCount,
          ChartTypeEnum.Histogram,
        ),
        sizingType: SizingOptions.Fit,
        chartWidth: 900,
        chartHeight: 528,
        showTitle: true,
        histogramChartType,
        logarithmic: false,
      };
    }

    case ChartTypeEnum.DOE:
      return {
        xAxis: InitialDOEXAxisChoices,
        centerLineAll: CenterLineOptions.Mean,
        centerLineGroups: CenterLineOptions.Mean,
        showGroupLegend: true,
        showMeanDiamonds: false,
        pooledVariance: false,
        colorBy: DOEColorByChoices[0],
        labelBy: labelByChoices,
        labelByChoices: allLabelByChoices,
        showLegend: true,
        showLabels: false,
        jitter: 0,
        xMinAxis: 0,
        xMaxAxis: 30,
        yMinAxis: 0,
        yMaxAxis: 1,
        chartTitle: getChartTitle(chartsTitles, chartsCount, ChartTypeEnum.DOE),
        sizingType: SizingOptions.Fit,
        chartWidth: 900,
        chartHeight: 528,
        showTitle: true,
      };
  }
};

export const getInitialAxesBasesForHistogram = (modelName: ModelName) => {
  return modelName === ModelName.Material
    ? 'maker'
    : modelName === ModelName.Process
    ? 'operator'
    : modelName === ModelName.Measurement
    ? 'type'
    : modelName === ModelName.Experiment
    ? 'leader'
    : modelName === ModelName.Program
    ? 'uploader'
    : modelName === ModelName.MaterialType
    ? 'owner'
    : modelName === ModelName.ProcessType
    ? 'owner'
    : modelName === ModelName.MeasurementType
    ? 'owner'
    : modelName === ModelName.ControlType
    ? 'owner'
    : modelName === ModelName.User
    ? 'site'
    : modelName === ModelName.Team
    ? 'leader'
    : modelName === ModelName.Site
    ? 'leader'
    : modelName === ModelName.Instrument
    ? 'owner'
    : '';
};

export const checkCharDataEqualRawData = (
  chartData: Record<string, any>[],
  rawData: Record<string, any>[],
) => {
  const result = {
    areKeysEqual: true,
    areValuesEquals: true,
    chartDataHasFullKeys: false,
    rawDataHasFullKeys: false,
  };

  const idsFromDotsArray = chartData.map((dot) => dot.db_id);
  const idsFromDataArray = rawData.map((d) => d.db_id);

  const keysFromDotsArray = Object.keys(chartData[0] ?? {});
  const keysFromDataArray = Object.keys(rawData[0] ?? {});

  if (keysFromDotsArray.length >= keysFromDataArray.length) {
    result.chartDataHasFullKeys = true;
  } else {
    result.rawDataHasFullKeys = true;
  }

  if (idsFromDotsArray.length !== idsFromDataArray.length) {
    result.areValuesEquals = false;
  }

  if (keysFromDotsArray.length !== keysFromDataArray.length) {
    result.areKeysEqual = false;
  }

  if (result.areValuesEquals) {
    idsFromDotsArray.sort((a, b) => a - b);
    idsFromDataArray.sort((a, b) => a - b);

    for (let i = 0; i < idsFromDotsArray.length; i++) {
      if (idsFromDotsArray[i] !== idsFromDataArray[i]) {
        result.areValuesEquals = false;
      }
    }
  }

  if (result.areKeysEqual) {
    keysFromDotsArray.sort((a, b) => a.localeCompare(b));
    keysFromDataArray.sort((a, b) => a.localeCompare(b));

    for (let i = 0; i < keysFromDotsArray.length; i++) {
      if (keysFromDotsArray[i] !== keysFromDataArray[i]) {
        result.areKeysEqual = false;
      }
    }
  }

  return result;
};
