import { RowNode } from 'ag-grid-community';
import * as R from 'ramda';

import { ModelName } from 'utils/enum';
import * as actionTypes from './RelatedActionsTypes';

export const STATE_KEY = 'related';

export type InitialTableStateTypes = {
  data: Record<string, any>[];
  error: boolean | string;
  loading: boolean;
  filterModel: Record<string, any>;
  lastRow: number;
  selectedNodes: Record<string, any>[];
  totalCount: number;
  isDirectional: boolean;
  explanation: string;
};

export type InitialRelatedStateType = {
  orientingTable: {
    loading: boolean;
    data: RowNode[];
    error: boolean;
  };
  tables: {
    [key in ModelName]: InitialTableStateTypes;
  };
};

const initialTableState: InitialTableStateTypes = {
  data: [],
  error: false,
  loading: false,
  filterModel: {},
  lastRow: 0,
  selectedNodes: [],
  totalCount: 0,
  isDirectional: false,
  explanation: '',
};

const initialState: InitialRelatedStateType = {
  orientingTable: {
    loading: false,
    data: [],
    error: false,
  },
  tables: {
    [ModelName.Material]: {
      ...initialTableState,
    },
    [ModelName.Process]: {
      ...initialTableState,
    },
    [ModelName.Measurement]: {
      ...initialTableState,
    },

    [ModelName.Experiment]: {
      ...initialTableState,
    },
    [ModelName.Program]: {
      ...initialTableState,
    },

    [ModelName.MaterialType]: {
      ...initialTableState,
    },
    [ModelName.ProcessType]: {
      ...initialTableState,
    },
    [ModelName.MeasurementType]: {
      ...initialTableState,
    },
    [ModelName.ControlType]: {
      ...initialTableState,
    },

    [ModelName.User]: {
      ...initialTableState,
    },
    [ModelName.Team]: {
      ...initialTableState,
    },
    [ModelName.Site]: {
      ...initialTableState,
    },
    [ModelName.Instrument]: {
      ...initialTableState,
    },
    [ModelName.Version]: {
      ...initialTableState,
    },
  },
};

const RelatedReducer = (state = initialState, action: any) => {
  const { payload } = action;

  switch (action.type) {
    case actionTypes.FETCH_ORIENTING_TABLE_START: {
      return R.mergeDeepRight(state, { orientingTable: { loading: true } });
    }
    case actionTypes.FETCH_ORIENTING_TABLE_SUCCESS: {
      return R.mergeDeepRight(state, {
        orientingTable: {
          data: payload,
          loading: false,
        },
      });
    }
    case actionTypes.FETCH_ORIENTING_TABLE_ERROR: {
      return R.mergeDeepRight(state, { orientingTable: { error: payload } });
    }

    case actionTypes.FETCH_RELATED_TABLE_START: {
      return R.mergeDeepRight(state, {
        tables: { [payload.destModel]: { loading: true } },
      });
    }
    case actionTypes.FETCH_RELATED_TABLE_SUCCESS: {
      const shouldUpdateState = payload.shouldUpdateState;

      if (!shouldUpdateState) {
        return R.mergeDeepRight(state, {
          tables: {
            [payload.destModel]: {
              loading: false,
            },
          },
        });
      }
      return R.mergeDeepRight(state, {
        tables: {
          [payload.destModel]: {
            data: payload?.data?.rows || [],
            lastRow: payload?.data?.lastRow || 0,
            totalCount: payload?.data?.total_count || 0,
            isDirectional: payload?.data?.is_directional || false,
            explanation: payload?.data?.explanation || '',
            loading: false,
          },
        },
      });
    }
    case actionTypes.FETCH_RELATED_TABLE_ERROR: {
      return R.mergeDeepRight(state, {
        tables: {
          [payload.destModel]: { error: payload.error },
        },
      });
    }

    case actionTypes.SET_RELATED_TABLE_SELECTED_NODES: {
      return R.mergeDeepRight(state, {
        tables: {
          [payload.destModel]: {
            selectedNodes: payload.nodes,
          },
        },
      });
    }

    default:
      return state;
  }
};

export default RelatedReducer;
