import { push } from '@lagunovsky/redux-react-router';
import { Dispatch } from 'redux';

import {
  getExpirationDateToken,
  getRefreshToken,
  getAccessToken,
} from 'helpers/localeStorage';
import { isTokenExpired } from './utils';
import axios from 'config/AxiosConfig';
import * as actionTypes from './AuthActionsTypes';
import { NavigateFunction } from 'react-router-dom';
import { API_ENDPOINTS, ModelName } from 'utils/enum';

export const fetchUserInfo =
  (navigate?: NavigateFunction) => async (dispatch: Dispatch<any>) => {
    dispatch({ type: actionTypes.FETCH_USER_INFO_START });

    try {
      const { data } = await axios.get(`/${API_ENDPOINTS.auth.user}`);
      const teamQuery = data?.info?.teams?.length
        ? `?team:in=${data?.info?.teams.join(',')}`
        : '';

      dispatch({
        type: actionTypes.FETCH_USER_INFO_SUCCESS,
        payload: data?.info,
      });

      if (navigate && teamQuery) {
        navigate(`/${ModelName.Experiment}${teamQuery}`);
      }
    } catch (err) {
      dispatch({
        type: actionTypes.FETCH_USER_INFO_ERROR,
        payload: err.message,
      });
    }
  };

interface loginProps {
  email: string;
  password: string;
}
export const login =
  (navigate: NavigateFunction, userInfo: loginProps) =>
  async (dispatch: Dispatch<any>) => {
    dispatch({ type: actionTypes.LOGIN_START });
    dispatch({ type: actionTypes.FETCH_USER_INFO_START });

    try {
      const { data } = await axios.post(API_ENDPOINTS.auth.login, {
        ...userInfo,
      });
      if (data.error) {
        return dispatch({
          type: actionTypes.LOGIN_ERROR,
          payload: data.message,
        });
      }
      dispatch({
        type: actionTypes.LOGIN_SUCCESS,
        payload: data,
      });
    } catch (err) {
      return dispatch(logout());
    }

    try {
      const { data: userData } = await axios.get(`/${API_ENDPOINTS.auth.user}`);
      dispatch({
        type: actionTypes.FETCH_USER_INFO_SUCCESS,
        payload: userData?.info,
      });
      const teamQuery = userData?.info.teams?.length
        ? `?team:in=${userData.info.teams.join(',')}`
        : '';

      navigate(`/experiment${teamQuery}`);
    } catch (err) {
      dispatch({
        type: actionTypes.FETCH_USER_INFO_ERROR,
        payload: err.message,
      });
    }
  };

interface loginRefreshProps {
  refresh_token: string;
}
export const loginRefresh =
  ({ refresh_token }: loginRefreshProps) =>
  async (dispatch: Dispatch<any>) => {
    dispatch({ type: actionTypes.LOGIN_REFRESH_START });

    try {
      const { data } = await axios.post(API_ENDPOINTS.auth.loginRefresh, {
        refresh_token,
      });

      return data.error
        ? dispatch(logout())
        : dispatch({
            type: actionTypes.LOGIN_REFRESH_SUCCESS,
            payload: data,
          });
    } catch (err) {
      dispatch({
        type: actionTypes.LOGIN_REFRESH_ERROR,
        payload: err.message,
      });
      return dispatch(logout());
    }
  };

export const logout =
  (access_token: string | null = null) =>
  async (dispatch: Dispatch) => {
    dispatch({ type: actionTypes.LOGOUT_START });

    try {
      if (access_token) {
        await axios.post(`/${API_ENDPOINTS.auth.logout}`, { access_token });
      }
      dispatch({
        type: actionTypes.LOGOUT_SUCCESS,
      });
      dispatch(push('/auth/login'));
    } catch (err) {
      dispatch({
        type: actionTypes.LOGOUT_SUCCESS,
      });
      dispatch(push('/auth/login'));
    }
  };

export const checkAuth = () => async (dispatch: any) => {
  try {
    const expirationTokenDate = getExpirationDateToken();
    const refresh_token = getRefreshToken();
    const access_token = getAccessToken();

    if (
      !expirationTokenDate ||
      (isTokenExpired(expirationTokenDate) && !refresh_token)
    ) {
      return dispatch(logout(access_token));
    }

    if (isTokenExpired(expirationTokenDate) && refresh_token) {
      return dispatch(loginRefresh({ refresh_token }));
    }
  } catch (err) {
    return dispatch(logout());
  }
};

interface pwChangeProps {
  curPassword: string;
  newPassword: string;
}
export const pwChange =
  ({ curPassword, newPassword }: pwChangeProps) =>
  async (dispatch: Dispatch) => {
    dispatch({ type: actionTypes.AUTH_PWCHANGE_START });

    try {
      const access_token = getAccessToken();
      const { data } = await axios.post(API_ENDPOINTS.auth.pwchange, {
        prev_password: curPassword,
        curr_password: newPassword,
        access_token,
      });

      if (data.error) {
        return dispatch({
          type: actionTypes.AUTH_PWCHANGE_ERROR,
          payload: data.message,
        });
      }
      dispatch({
        type: actionTypes.AUTH_PWCHANGE_SUCCESS,
        payload: data.message,
      });
      dispatch(push('/experiment'));
    } catch (err) {
      return dispatch({
        type: actionTypes.AUTH_PWCHANGE_ERROR,
        payload: err.message,
      });
    }
  };

interface pwForgotProps {
  email: string;
}
export const pwForgot =
  ({ email }: pwForgotProps) =>
  async (dispatch: Dispatch) => {
    dispatch({ type: actionTypes.AUTH_PWFORGOT_START });

    try {
      const { data } = await axios.post(`/${API_ENDPOINTS.auth.pwforgot}`, {
        email,
      });

      if (data.error) {
        return dispatch({
          type: actionTypes.AUTH_PWFORGOT_ERROR,
          payload: data.message,
        });
      }
      dispatch({
        type: actionTypes.AUTH_PWFORGOT_SUCCESS,
        payload: data.message,
      });
      dispatch(push('/auth/password-reset-sent'));
    } catch (err) {
      return dispatch({
        type: actionTypes.AUTH_PWFORGOT_ERROR,
        payload: err.message,
      });
    }
  };

interface pwForgotConfirmProps {
  email: string | null;
  code: string | null;
  password: string;
}
export const pwForgotConfirm =
  ({ email, code, password }: pwForgotConfirmProps) =>
  async (dispatch: Dispatch) => {
    dispatch({ type: actionTypes.AUTH_PWFORGOT_CONFIRM_START });

    try {
      const { data } = await axios.post(API_ENDPOINTS.auth.pwforgotconfirm, {
        email,
        code,
        password,
      });

      if (data.error) {
        return dispatch({
          type: actionTypes.AUTH_PWFORGOT_CONFIRM_ERROR,
          payload: data.message,
        });
      }
      dispatch({
        type: actionTypes.AUTH_PWFORGOT_CONFIRM_SUCCESS,
        payload: data.message,
      });
      dispatch(push('/auth/password-update-success'));
    } catch (err) {
      return dispatch({
        type: actionTypes.AUTH_PWFORGOT_CONFIRM_ERROR,
        payload: err.message,
      });
    }
  };

export const authResetFormState = () => ({
  type: actionTypes.AUTH_RESET_FORM,
});
